import React from 'react';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import { DashboardComparisonData, DashboardComparisonMaturity } from '../../../domain/interfaces/DashboardComparisonData';
import { Alert } from '../../../viewComponents/Alerts';
import { useDashboards } from '../../../viewComponents/hooks/useDashboards';
import { useSamples } from '../../../viewComponents/hooks/useSamples';
import { useModal } from '../../../viewComponents/modal/useModal';

export interface DashboardComparisonBenchmark {
	sampleName: string;
	data: DashboardComparisonData;
	maturity: DashboardComparisonMaturity;
}

export const createDashboardComparisonBenchmarkModal = (
	open: boolean,
	setOpen: (value: boolean) => void,
	setBenchmark: (value: DashboardComparisonBenchmark) => void
): IModal | undefined => {
	const { entity: dashboardEntity } = useDashboards();
	const { samples: sampleList } = useSamples();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [sampleId, setSampleId] = React.useState<string>('');
	const [isBenchmarkCalculate, setIsBenchmarkCalculate] = React.useState<boolean>(false);
	const samples = sampleList?.sort(
		(a, b) => a.name.localeCompare(b.name)
	);

	if (!open) return;

	const clear = () => {
		setSampleId('');
		setLoading(false);
		setIsBenchmarkCalculate(false);
		useModal(false, { body: undefined, header: '', visible: false });
	};

	const update = () => {
		setLoading(true);
		setIsBenchmarkCalculate(true);
		dashboardEntity?.queryDashboardComparisonBenchmark({ sampleId })
			.then((data) => {
				setBenchmark({ sampleName: samples?.find(s => s.id === sampleId)?.name ?? '', data: data.data, maturity: data.maturity });
				setOpen(false);
				clear();
			}).catch(() => clear());
	};

	const body = <div className="dashboard_modal">
		<span>{translate('dashboard.benchmark')} ({translate('required')})</span>
		<select
			value={sampleId}
			onChange={(e) => setSampleId(e.target.value)}
		>
			<option value="">{translate('dashboard.benchmark.select')}</option>
			{samples?.map((sample) => (
				<option key={sample.id} value={sample.id}>{sample.name}</option>
			))}
		</select>
		{isBenchmarkCalculate && <Alert variant={'info'}>{translate('dashboard.benchmark.calculate')}</Alert>}
	</div>;

	return {
		action: update,
		actionText: 'validate',
		body,
		header: translate('dashboard.modal.benchmark.header'),
		load: loading,
		onClose(): void {
			setOpen(false);
			clear();
		},
		visible: true
	};
};