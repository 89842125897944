import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { ResultArrowComparison } from '../ResultArrowComparison';
import { unique } from './Unique';
import './InfoSamplesInfo.scss';
import { Tip } from '../../tip/Tip/Tip';

interface IInfo {
	id: string;
	name: string;
	desc?: string;
}

export const InfoSamplesInfo = ({ info }: { info?: IInfo[] }): JSX.Element => {
	if (!info) return (
		<h4 className="info-samples_title">
			{translate('info.block.noSample')}
		</h4>
	);

	const oneDescription = info.length === 1 || info.filter(s => s.desc && s.desc !== '').filter(unique).length === 1;

	const titles = (
		<>
			<h4 className={'info-samples_title'}>
				{translate('info.block.preSample') + info.map(s => s.name).filter(unique).join(', ')}
			</h4>

			{oneDescription && (
				<h4 className={'info-samples_title'}>
					{translate('description')}: {info.find(i => i.desc)?.desc}
				</h4>
			)}

			{!oneDescription && info.map(s => s.desc && (
				<h4 key={s.id} className={'info-samples_title'}>
					{s.name} {translate('description')}: {s.desc}
				</h4>
			))}
		</>
	);

	const basicContent = (
		<div className={'info-samples'}>
			{titles}
		</div>
	);

	const expandedContent = (
		<div className={'info-samples'}>
			{titles}
			<div className={'info-samples_content'}>
				<p><ResultArrowComparison arrow={'max'} color={'grey'}/> {translate('info.block.indicatorMax')}</p>
				<p><ResultArrowComparison arrow={'up'} color={'grey'}/> {translate('info.block.indicatorUp')}</p>
				<p><ResultArrowComparison arrow={'down'} color={'grey'}/> {translate('info.block.indicatorDown')}</p>
				<p><ResultArrowComparison arrow={'min'} color={'grey'}/> {translate('info.block.indicatorMin')}</p>
			</div>
		</div>
	);

	return (
		<Tip
			type={'normal'}
			border={'lightgray'}
			fullContent={expandedContent}
		>
			{basicContent}
		</Tip>
	);
};