// Global config file

/**** Global App settings ****/
// All to get corp-id colors centralized
export const CORP_COLORS = {
	darkGreen: '#05452E',
	lightGreen: '#52C78A',
	green: '#339E69',
	orange: '#ea5f1e',
	yellow: '#ffde55',
	red: '#E74C3C',
	hl1: '#FC63B2',
	hl2: '#FAEBA1',
	gray: '#494A4A',
	greenMary: '#47835B'
};

// Colors used in graphs
export const GRAPH_COLORS = {
	LIGHT_GREEN: '#61B987',
	DARK_GREEN: '#038245',
	YELLOW: '#FFD650',
	ORANGE: '#F9B406',
	PINK: '#EB68A3',
	DARK_PINK: '#B6477F',
	BLUE: '#5ABAEA',
	DARK_BLUE: '#1F6885',
	PURPLE: '#A863A5',
	DARK_PURPLE: '#6C4796',
	GRASS_GREEN: '#C5D300',
	DARK_ORANGE: '#F77B0B',
	RED: '#CE4532',
	GREY: '#808080',
	AZURE: '#00AFAF',
	BROWN: '#D09111',
	DENIM: '#2e5eaa',
	PINK_LAVENDER: '#e2afde',
	EIFEIL_BEIGE:'#E8DAB2',
	FEDERAL_BLUE: '#1b065e',
	JET: '#2b2c28',
	TROPICAL_INDIGO: '#3b3b98',
};

// Neutral colors used in graphs, without green or red colors that could be interpreted as positive or negative
export const NEUTRAL_GRAPH_COLORS = {
	ORANGE: '#F9B406',
	PINK: '#EB68A3',
	DARK_PINK: '#B6477F',
	BLUE: '#5ABAEA',
	DARK_BLUE: '#1F6885',
	PURPLE: '#A863A5',
	GREY: '#808080',
	AZURE: '#00AFAF',
	BROWN: '#D09111',
};


/**
 * @param n number of colors to return
 * @param inNeutral boolean to return neutral colors
 * @returns string[] of colors
 * @description Returns an array of n colors from the GRAPH_COLORS object
 */
export const getColors = (n: number, inNeutral = false): string[] => {
	const colorKeys = Object.keys(inNeutral ? NEUTRAL_GRAPH_COLORS : GRAPH_COLORS);
	const colors = [];
	for (let i = 0; i < n; i++) {
		const colorKey = colorKeys[i % colorKeys.length];
		colors.push((GRAPH_COLORS as {[key: string]: string})[colorKey]);
	}
	return colors;
};

/**
 * @param {number} index
 * @returns {string} color
 * @description Returns the color associated to the index
 */
export const pickColorByIndex = (index: number): string => {
	const colorKeys = Object.keys(GRAPH_COLORS);
	const colorKey = colorKeys[index % colorKeys.length];
	return (GRAPH_COLORS as {[key: string]: string})[colorKey];
};

/**
 * @param level number
 * @description Returns the color associated to the level
 * @returns string (color)
 */
export const getColorByScore = (level: number): string => {
	switch (level) {
		case 3:
			return CORP_COLORS.green;
		case 2:
			return CORP_COLORS.yellow;
		case 1:
			return CORP_COLORS.orange;
		case 0:
			return CORP_COLORS.red;
		default:
			return CORP_COLORS.gray;
	}
};

// Base URL
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

// Base URL for API requests
export const BASE_API_URL = `${process.env.REACT_APP_BASE_API_URL || 'http://localhost:8000'}/gql`;
export const BASE_APP_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:8000';
export const MAINTENANCE_APP_URL = process.env.REACT_APP_MAINTENANCE_APP_URL || 'http://localhost:8000';

export const EMERGENCY_MAIL = 'emergency@resilio.tech';
export const SUPPORT_MAIL = 'support@resilio.tech';

export const ICON_DATA = (input: boolean): string => {
	if (input) return 'fa-solid fa-check';
	return 'fa-solid fa-times';
};


/* MATURITY view */
// Picture parameters for maturity view
export const PICTURE_DATA = {
	difficulty: {
		'1': { icon: 'fa-solid fa-cog', repeat: 1, text: 'maturity.difficulty.easy' , color: CORP_COLORS.green },
		'2': { icon: 'fa-solid fa-cog', repeat: 2, text: 'maturity.difficulty.standard' , color: CORP_COLORS.yellow },
		'3': { icon: 'fa-solid fa-cog', repeat: 3, text: 'maturity.difficulty.hard' , color: CORP_COLORS.red },
	},
	priority: {
		'3': { icon: 'fa-solid fa-check-circle', repeat: 3, text: 'maturity.priority.priority', color: CORP_COLORS.darkGreen },
		'2': { icon: 'fa-solid fa-check-circle', repeat: 2, text: 'maturity.priority.recommended', color: CORP_COLORS.green },
		'1': { icon: 'fa-solid fa-check-circle', repeat: 1, text: 'maturity.priority.notPriority', color: CORP_COLORS.lightGreen },
	}
};

// CMMI Levels
export const CMMI_VALUES = [
	{ value: '', text: '...' },
	{ value: 1, text: 'results.maturity.initial' },
	{ value: 2, text: 'results.maturity.reproducible' },
	{ value: 3, text: 'results.maturity.defined' },
	{ value: 4, text: 'results.maturity.mastered' },
	{ value: 5, text: 'results.maturity.optimized' },
	{ value: 6, text: 'results.maturity.notApplicable' },
];