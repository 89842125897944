import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { ManageEquipmentEntity } from '../../domain/entities/ManageEquipmentEntity';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import { EquipmentTypeCategory } from '../../domain/data/entries/EquipmentTypeCategory';

type ManageEquipmentHookType = IDataDTO & { entity: ManageEquipmentEntity | undefined, data: EquipmentTypeCategory[] };

export const useManageEquipment = (): ManageEquipmentHookType => {
	const { loading, error, data } = useSelector((state: State) => state.dataEquipmentStatus);
	const categories = error ? [] : data?.get() ?? [];
	return { loading, error, entity: data, data: categories };
};