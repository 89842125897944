import React, { useState } from 'react';
import './titleHeader.scss';
import { SideBarContentItemList } from '../../template/sidebar/sidebar';
import { translate } from '../../../infrastructure/translations/translate';
import { useLocation, useParams } from 'react-router-dom';
import { useDatasets } from '../../hooks/useDatasets';
import { useStudies } from '../../hooks/useStudies';
import { useStaticPages } from '../../hooks/useStaticPages';
import { createStudyChecklistModal } from '../../modal/StudyChecklistModal/StudyChecklistModal';
import { useModal } from '../../modal/useModal';
import { useUser } from '../../hooks/useUser';
import { useRequestCalculationModal } from '../../modal/RequestCalculationModal/RequestCalculationModal';
import { LabelList } from '../../utils/LabelList/LabelList';

/**
 * This component is used to display dynamically the titles according to the current page
 *
 * @param content : SideBarContentItemList. It contains the list of items to display for the current page.
 *
 * @author : Yvan Petruzzi
 */
const TitleHeader = ({ content }: SideBarContentItemList): JSX.Element => {
	const {
		study: currentStudyId,
		dataset: currentDatasetId,
		page: currentPage,
		dashboard: currentDashboard
	} = useParams<{ study: string, dataset: string, page: string, dashboard: string }>();
	const { studies } = useStudies();
	const { data: user } = useUser();

	let datasetIds: string[] = [];
	if (currentDatasetId === 'all') {
		const study = studies?.find(s => s.id === currentStudyId);
		if (study) datasetIds = study.datasetsId;
	} else {
		datasetIds = currentDatasetId ? [currentDatasetId] : [];
	}

	const { datasets } = useDatasets(datasetIds);
	const { pathname } = useLocation();
	const contact = pathname.includes('contact');

	const action = useParams<{ action: string }>();
	const staticPageId = useParams<{ name: string }>();
	const currentStudy = studies?.find(s => s.id === currentStudyId);
	const currentDataset = datasets?.find(d => d.id === currentDatasetId);
	const { pages } = useStaticPages();

	const [openChecklistModal, setOpenChecklistModal] = React.useState<boolean>(false);
	const [openRequestCalculationModal, setOpenRequestCalculationModal] = useState(false);

	const inKanbanView = pathname.includes('study') && currentStudyId !== undefined && !currentDatasetId;
	const canSeeStudyChecklist = user?.permissions?.includes('login.can_see_study_checklist');
	const isRdbConsultant = user?.groups.some(group => group.name === 'Consultant Resilio');
	// if the user is a rdb consultant he doesn't need to request calculation (he can do it directly)
	const canRequestCalculation = user?.permissions.includes('login.can_request_calculation') && !isRdbConsultant;

	useModal(openChecklistModal, createStudyChecklistModal(openChecklistModal, setOpenChecklistModal));
	useModal(openRequestCalculationModal, useRequestCalculationModal(openRequestCalculationModal, setOpenRequestCalculationModal, 'all', currentStudyId || '', user?.myCompany.name || ''));

	// If action.action is results we want to display "results" as title
	// Since the categories is results are defined by #id for the autoscroll, the precise title is not required
	if (action.action === 'results') {
		return (<h1>{translate(`sidebar.${action.action}`)}</h1>);
	}

	// if we are on a static page, we want to display the name of the page
	if (pathname.includes('static')) {
		return (<h1>{pages.find(p => p.id === staticPageId.name)?.name}</h1>);
	}

	const displayLabels = !!(currentStudy && currentStudy.labels && currentStudy.labels.length > 0);

	// Get the title to display according to the current page
	// It can often be undefined so we have to check on other values
	const content_title = content.list.find((item) => item.current)?.name;

	let title: React.ReactElement | string;
	const archived = currentDataset?.archived || currentStudy?.archived;
	if (!content_title) { 
		if (!action.action) {
			// This line allows us to une page if it exists, if not we will take the currentStudy name and if
			// it does not exists we know that we are on the home page
			title = (currentPage && translate(`${currentPage}`)) ||
				(contact && translate('contact.title')) ||
				(currentDashboard && translate('dashboard.title')) ||
				(currentStudy?.name || translate('sidebar.yourStudies'));
		} else {
			// If action.action has a value, we use the translation of the sidebar elements
			// to catch in which sub category we are
			title = translate(`sidebar.${action.action}`);
		}
	} else {
		title = content_title;
	}
	if (archived) {
		title = <><span className={'red'}>{translate('archived')}</span> - {title}</>;
	}
	return (<div className={'study_title'}>
		<h1>
			{title}
		</h1>
		{inKanbanView && <div className={'study_actions'}>
			{canSeeStudyChecklist && <button aria-label={translate('home.study.checklistModal.title') as string} title={translate('home.study.checklistModal.title') as string}>
				<i className='fa-light fa-ballot-check' onClick={() => setOpenChecklistModal(!openChecklistModal)}/>
			</button>}
			{canRequestCalculation && <button aria-label={translate('studyKanban.requestCalculation') as string} title={translate('validation.calculationRequest') as string}>
				<i className='fa-light fa-calculator' onClick={() => setOpenRequestCalculationModal(!openRequestCalculationModal)}/>
			</button>}
		</div>}
		{displayLabels && <LabelList labels={currentStudy.labels.map(l => ({ name: l }))} />}
	</div>);
};

export default TitleHeader;
