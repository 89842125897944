import { Entity } from '../core/Entity';
import {
	ApolloClientMutateEquipmentCategoriesOrder,
	ApolloClientMutateEquipmentOrder,
	ApolloClientQueryEquipmentByIds,
	ApolloClientQueryEquipmentTypeCategories,
	ApolloClientQueryEquipmentTypes,
	ApolloClientQueryImpactFactors,
	ApolloClientQueryModalEquipmentsInfos,
	ApolloClientQueryMutateEquipmentTypeManagement,
	ApolloClientQueryResilioDbSuggestions,
	ApolloClientQuerySearchEquipmentExplore,
	AppolloClientCountriesOfUse
} from '../../infrastructure/ApolloClass/ManageEquipmentClass';
import { MUTATE_EQUIPMENT_CATEGORIES_ORDER } from '../../infrastructure/ApolloClient/requests/MUTATE_EQUIPMENT_CATEGORIES_ORDER';
import { MUTATE_EQUIPMENT_ORDER } from '../../infrastructure/ApolloClient/requests/MUTATE_EQUIPMENT_ORDER';
import { MUTATE_EQUIPMENT_TYPE_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_EQUIPMENT_TYPE_MANAGEMENT';
import { QUERY_EQUIPMENT_TYPES } from '../../infrastructure/ApolloClient/requests/QUERY_EQUIPMENT_TYPES';
import { QUERY_EQUIPMENT_BY_IDS } from '../../infrastructure/ApolloClient/requests/QUERY_EQUIPMENT_BY_IDS';
import { QUERY_SEARCH_EQUIPMENT_EXPLORE } from '../../infrastructure/ApolloClient/requests/QUERY_SEARCH_EQUIPMENT_EXPLORE';
import { QUERY_EQUIPMENT_CATEGORIES } from '../../infrastructure/ApolloClient/requests/QUERY_EQUIPMENT_CATEGORIES';
import { QUERY_RESILIO_DB_SUGGESTIONS } from '../../infrastructure/ApolloClient/requests/QUERY_RESILIO_DB_SUGGESTIONS';
import { IMutateEquipmentCategoriesOrderInput } from '../interfaces/IMutateEquipmentCategoriesOrder';
import { IEquipmentOrder } from '../interfaces/IEquipmentOrder';
import { IQueryEquipmentTypeCategoriesInput } from '../interfaces/IQueryEquipmentTypeCategories';
import { IQueryEquipmentTypesInput } from '../interfaces/IQueryEquipmentTypes';
import { EquipmentType } from '../data/entries/EquipmentType';
import { EquipmentTypeCategory } from '../data/entries/EquipmentTypeCategory';
import { IQueryEquipmentByIdsInput, IQueryEquipmentByIdsOutput } from '../interfaces/IQueryEquipmentByIds';
import { IQuerySearchEquipmentExploreInput, IQuerySearchEquipmentExploreOutput } from '../interfaces/IQuerySearchEquipmentExplore';
import { IQueryResilioDbSuggestionsInput, IQueryResilioDbSuggestionsOutput } from '../interfaces/IQueryResilioDbSuggestions';
import { IQueryImpactFactorsInput } from '../interfaces/IQueryImpactFactors';
import { IImpactFactor } from '../interfaces/IImpactFactor';
import { QUERY_IMPACT_FACTORS } from '../../infrastructure/ApolloClient/requests/QUERY_IMPACT_FACTORS';
import { IMutateEquipmentTypeManagementInput, IMutateEquipmentTypeManagementOutput } from '../interfaces/IMutateEquipmentTypeManagement';
import { QUERY_COUNTRIES_OF_USE } from '../../infrastructure/ApolloClient/requests/QUERY_COUNTRIES_OF_USE';
import { IQueryCountriesOfUseResponse } from '../interfaces/IQueryCountriesOfUse';
import { QUERY_MODAL_EQUIPMENTS_INFOS } from '../../infrastructure/ApolloClient/requests/QUERY_MODAL_EQUIPMENTS_INFOS';
import { IQueryModalEquipmentsInfosInput, IQueryModalEquipmentsInfosOutput } from '../interfaces/IQueryModalEquipmentsInfos';

export class ManageEquipmentEntity extends Entity {
	public data: EquipmentTypeCategory[] | undefined = undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.queryEquipmentTypes ??= this.app.installer(ApolloClientQueryEquipmentTypes, QUERY_EQUIPMENT_TYPES);
		this.app.adapter.queryImpactFactors ??= this.app.installer(ApolloClientQueryImpactFactors, QUERY_IMPACT_FACTORS);
		this.app.adapter.queryEquipmentByIds ??= this.app.installer(ApolloClientQueryEquipmentByIds, QUERY_EQUIPMENT_BY_IDS);
		this.app.adapter.querySearchEquipmentExplore ??= this.app.installer(ApolloClientQuerySearchEquipmentExplore, QUERY_SEARCH_EQUIPMENT_EXPLORE);
		this.app.adapter.queryResilioDbSuggestions ??= this.app.installer(ApolloClientQueryResilioDbSuggestions, QUERY_RESILIO_DB_SUGGESTIONS);
		this.app.adapter.queryEquipmentTypeCategories ??= this.app.installer(ApolloClientQueryEquipmentTypeCategories, QUERY_EQUIPMENT_CATEGORIES);
		this.app.adapter.queryCountriesOfUse ??= this.app.installer(AppolloClientCountriesOfUse, QUERY_COUNTRIES_OF_USE);
		this.app.adapter.mutateEquipmentTypeManagement ??= this.app.installer(ApolloClientQueryMutateEquipmentTypeManagement, MUTATE_EQUIPMENT_TYPE_MANAGEMENT);
		this.app.adapter.mutateEquipmentCategoriesOrder ??= this.app.installer(ApolloClientMutateEquipmentCategoriesOrder, MUTATE_EQUIPMENT_CATEGORIES_ORDER);
		this.app.adapter.mutateEquipmentOrder ??= this.app.installer(ApolloClientMutateEquipmentOrder, MUTATE_EQUIPMENT_ORDER);
		this.app.adapter.queryModalEquipmentsInfos ??= this.app.installer(ApolloClientQueryModalEquipmentsInfos, QUERY_MODAL_EQUIPMENTS_INFOS);
		this.app.adapter.storeDataManageEquipment?.({ loading: false, data: this, error: null });
	}

	get() {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryEquipmentTypeCategories();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		return this.app.adapter.storeDataManageEquipment?.({ loading: false, data: this, error: null });
	}

	set(obj: EquipmentTypeCategory[]): Promise<void> | undefined {
		this.data = obj;
		return this.change();
	}

	update(): Promise<void> | undefined { return this.change(); }

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/
	async queryEquipmentTypeCategories(input?: IQueryEquipmentTypeCategoriesInput): Promise<EquipmentTypeCategory[]> {
		const data = await this.callApi(this.app.adapter.queryEquipmentTypeCategories, input);
		this.set(data.equipmentCategories);
		return data.equipmentCategories;
	}

	async queryEquipmentTypes(input?: IQueryEquipmentTypesInput): Promise<{equipmentTypes: EquipmentType[], numberOfEquipmentByCategory: number}> {
		const data = await this.callApi(this.app.adapter.queryEquipmentTypes, input);
		return data.equipmentTypesInfo;
	}

	async queryImpactFactors(input?: IQueryImpactFactorsInput): Promise<IImpactFactor[]> {
		const data = await this.callApi(this.app.adapter.queryImpactFactors, input);
		return data.impactFactors;
	}

	async queryEquipmentByIds(input: IQueryEquipmentByIdsInput): Promise<IQueryEquipmentByIdsOutput> {
		return this.callApi(this.app.adapter.queryEquipmentByIds, input);
	}

	async querySearchEquipmentExplore(input: IQuerySearchEquipmentExploreInput): Promise<IQuerySearchEquipmentExploreOutput['searchEquipmentExplore']> {
		const data = await this.callApi(this.app.adapter.querySearchEquipmentExplore, input);
		return data.searchEquipmentExplore;
	}

	async queryResilioDbSuggestions(input: IQueryResilioDbSuggestionsInput): Promise<IQueryResilioDbSuggestionsOutput['resilioDbSuggestions']> {
		const data = await this.callApi(this.app.adapter.queryResilioDbSuggestions, input);
		return data.resilioDbSuggestions;
	}

	async queryCountriesOfUse(): Promise<IQueryCountriesOfUseResponse['countriesOfUse']> {
		// We should use it if when we will find a solution for dynamic translation in the future
		const data = await this.callApi(this.app.adapter.queryCountriesOfUse);
		return data.countriesOfUse;
	}

	async queryModalEquipmentsInfos(input: IQueryModalEquipmentsInfosInput): Promise<IQueryModalEquipmentsInfosOutput['modalEquipmentsInfos']> {
		const data = await this.callApi(this.app.adapter.queryModalEquipmentsInfos, input);
		return data.modalEquipmentsInfos;
	}

	async mutateEquipmentCategoriesOrder(values: IMutateEquipmentCategoriesOrderInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateEquipmentCategoriesOrder, values);
		return data.mutateEquipmentCategoriesOrder.ok;
	}

	async mutateEquipmentOrder(values: IEquipmentOrder): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateEquipmentOrder, values);
		return data.mutateEquipmentOrder.ok;
	}

	async mutateEquipmentTypeManagement(values: IMutateEquipmentTypeManagementInput): Promise<IMutateEquipmentTypeManagementOutput> {
		const data = await this.callApi(this.app.adapter.mutateEquipmentTypeManagement, values);
		return data;
	}
}