import { gql } from '@apollo/client';

export const QUERY_USER = gql`
    query {
        me {
            id,
            username,
            email,
            ssoEmail,
            groups {
                id
                name
                translate
                grade
                privileged
                manageSameGrade
            }
            myCompany {
                id,
                name,
                start,
                end,
                emailDomains,
                ssoId,
                ssoSecret,
                ssoAuthorizationUrl,
                ssoProvider,
                ssoScope,
                style,
                logo,
                color
            },
            permissions,
            isSuperuser,
            isStaff,
            companiesToManage {
            	id,
            	name,
            	emailDomains,
            	style,
            	logo,
            	color
            },
            cguAccepted
            hasSeenUpdate
        }
    }
`;
