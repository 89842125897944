import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import ReactMarkdown from 'react-markdown';
import './StudyChecklistModal.scss';
import { useStudies } from '../../hooks/useStudies';
import { useParams } from 'react-router-dom';
import { useModal } from '../useModal';
import { useUser } from '../../hooks/useUser';
import remarkGfm from 'remark-gfm';
import { checkUserPermission } from '../../../utils/checkUserPermission';

export const createStudyChecklistModal = (
	openModal: boolean,
	setOpenModal: (open: boolean) => void,
) => {
	const { study: studyId } = useParams<{ study: string }>();
	const { data: user } = useUser();
	const canReadOnly = !checkUserPermission(user, 'login.can_read_and_write');
	const { entity: studyEntity, studies } = useStudies();
	const study = studies?.find(s => s.id === studyId);

	const checklistHelper = translate('home.study.checklistModal.helper') as string;
	const checklistFunctionalUnitTemplate = study?.functionalUnit.checklist ?? '';
	
	const displayFunctionalUnitChecklist = study !== undefined && (!study.checklist || study.checklist.length < 1) && checklistFunctionalUnitTemplate.length > 0;
	const displayChecklistHelper  = !displayFunctionalUnitChecklist && study !== undefined && study.checklist.length < 1;
	const checklistToDisplay = displayChecklistHelper ? checklistHelper : displayFunctionalUnitChecklist ? checklistFunctionalUnitTemplate : study?.checklist ?? '';
	
	const canEditStudyChecklist = user?.permissions?.includes('login.can_edit_study_checklist');
	
	const modalRef = useRef<HTMLDivElement>(null);

	const [isEditableView, setIsEditableView] = useState(false);
	const [text, setText] = useState(checklistToDisplay);
	const [textAreaDimensions, setTextAreaDimensions] = useState({ width: 0, height: 0 });

	useEffect(() => {
		setText(checklistToDisplay);
	}, [checklistToDisplay]);

	if (!openModal) return;

	/**
	 * This function is used as plugin in the ReactMarkdown component to handle the checkbox change event.
	 * It will update the markdown text with the new checkbox state, when the checkbox is clicked.
	 *
	 * @param linePosition - The line position of the checkbox in the markdown text.
	 * @param checked - The new state of the checkbox.
	 */
	const handleCheckboxChange = (linePosition: number | undefined, checked: boolean) => {
		if (canReadOnly) return;
		if (linePosition === undefined) return;
		const pos = linePosition - 1;
		const newMarkdownText = text.split('\n').map((line, index) => {
			if (index === pos) {
				const checkbox = line.match(/\[(x| )\]/);
				if (checkbox) {
					return line.replace(/\[(x| )\]/, checked ? '[x]' : '[ ]');
				}
			}
			return line;
		}).join('\n');

		setText(newMarkdownText);
		handleSave(newMarkdownText);
	};

	const clear = () => {
		setIsEditableView(false);
		useModal(false, { visible: false, body: undefined, header: '' });
		setOpenModal(false);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && e.ctrlKey) {
			handleSave();
		}
	};

	const handleSave = (newText?: string) => {
		if (canReadOnly) return;
		if (!study || !studyEntity) return;
		if (text !== study.checklist || (newText && newText !== study.checklist)) {
			studyEntity?.mutateStudyManagement({
				id: study.id,
				name: study.name,
				year: study.year,
				company: study.companyFk.id,
				checklist: newText ?? text,
				labels: study.labels,
				functionalUnit: study.functionalUnit.id,
				remove: false
			}).catch((err) => {
				setText(checklistToDisplay);
				if (!err) return;
			});
		}
		setIsEditableView(false);
	};

	const handleSwitchEditableView = () => {
		if (!isEditableView && modalRef.current) {
			const { width, height } = modalRef.current.getBoundingClientRect();
			setTextAreaDimensions({ width, height });
		}
		if (isEditableView) handleSave();
		setIsEditableView(!isEditableView);
	};

	const button = isEditableView
		? <button onClick={() => handleSave()} title={translate('save') as string} aria-label={translate('save') as string}>
			<i className={'fa-solid fa-check'}/>
		</button>
		: <button onClick={handleSwitchEditableView} title={translate('edit') as string} aria-label={translate('edit') as string}>
			<i className={'fa-solid fa-pen'}/>
		</button>;

	const body = (
		<div className="modal_wrapper" ref={modalRef}>
			<div className="title_wrapper">
				<h1>{translate('home.study.checklistModal.title')}</h1>
				{canEditStudyChecklist && !canReadOnly && button}
			</div>
			{isEditableView && !canReadOnly ? (
				<textarea
					style={{
						width: `${textAreaDimensions.width}px`,
						height: `${textAreaDimensions.height}px`,
					}}
					value={text}
					onChange={(e) => setText(e.target.value)}
					onKeyDown={handleKeyDown}
					rows={8}
				/>
			) : (
				<ReactMarkdown
					remarkPlugins={[remarkGfm]}
					components={{
						li: (node) => {
							if (node && node.children && Array.isArray(node.children)) {
								const children = node.children.map((c, idx) => {
									if (
										c &&
										typeof c === 'object' &&
										'props' in c &&
										c.props?.type === 'checkbox'
									) {
										const checked = c.props.checked ?? false;
										return (<input key={idx} type={'checkbox'} checked={checked} onChange={() => handleCheckboxChange(node.node?.position?.start.line, !checked)} />);
									}
									return c;
								});
								return <li className={node.className}>{children}</li>;
							}
							return <li className={node.className}>{node.children}</li>;
						},
					}}
				>
					{text}
				</ReactMarkdown>
			)}
		</div>
	);

	return {
		visible: true,
		header: undefined,
		body: body,
		onClose: () => clear(),
		footer: <></>,
	};
};