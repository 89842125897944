import { gql } from '@apollo/client';

export const QUERY_EQUIPMENT_CATEGORIES = gql`
	query equipmentCategories(
		$sourcesNames: [String]
	){
		equipmentCategories (
			sources: $sourcesNames
		){
			id,
			name,
			order,
			unit,
			unitShortName,
			identifier,
			rdbIdentifier
        }
    }
`;
