import React, { useEffect, useMemo, useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { ICompanyManage } from '../../../domain/data/entries/ICompanyManage';
import './GroupBy.css';
import { EquipmentType } from '../../../domain/interfaces/IEquipmentGroupBy';
import { Study } from '../../../domain/data/entries/Study';
import { StudyDataset } from '../../../domain/data/entries/StudyDataset';
import { ToolTipLight } from '../../tip/ToolTipLight/ToolTipLight';
import Modal from '../../modal/Modal';
import { resultValue } from '../utils/resultValue';
import Spinner from '../../utils/Spinner/Spinner';
import { useTransformedResultTableData } from '../../../utils/useTransformedResultTableData';
import { IResultTableDataInput, IResultTableDataOutput } from '../../../utils/getResultTableData';
import { useCompany } from '../../hooks/useCompany';
import { useResults } from '../../hooks/useResults';
import { useStudies } from '../../hooks/useStudies';
import { useDatasets } from '../../hooks/useDatasets';
import { useUser } from '../../hooks/useUser';
import ButtonLoading from '../../button/ButtonLoading/ButtonLoading';
import { adapter } from '../../../infrastructure';
import { v4 as uuid } from 'uuid';
import { Tip } from '../../tip/Tip/Tip';
import ErrorImage from '../../error/ErrorImage/ErrorImage';

function sortIcon(sorting: boolean, asc: boolean): JSX.Element {
	if (sorting) {
		if (asc) {
			return <i className={'groupby_margin_left fa-solid fa-sort-up'}/>;
		} else {
			return <i className={'groupby_margin_left fa-solid fa-sort-down'}/>;
		}
	}
	return <i className={'groupby_margin_left fa-solid fa-sort'}/>;
}

/**
 * @description GroupBy component for grouping equipment by company and study
 * @constructor
 * @param {EquipmentType[]} data
 * @param {boolean} groupByCategory
 * @param {string[]} life_cycle_steps
 * @param {boolean | 'byRow' | 'byColumn'} isRelative
 * @param {boolean} isNormalized
 * @returns {JSX.Element}
 *
 * @author Maxime Joly
 */
const GroupByTable = ({
	data,
	groupByCategory,
	life_cycle_steps,
	isRelative,
	isNormalized
}: {
	data: EquipmentType[],
	groupByCategory: boolean,
	life_cycle_steps: string[]
	isRelative: boolean | 'byRow' | 'byColumn',
	isNormalized: boolean
}): JSX.Element => {
	const sortable = [...data];
	const [sortKey, setSortKey] = useState<string>('');
	const [sortAsc, setSortAsc] = useState(true);

	/**
	 * @description Function to sort the list of equipment by the selected impact
	 * @param key
	 */
	const handleSort = (key: string) => {
		if (sortKey === key) {
			setSortAsc(!sortAsc);
		} else {
			setSortKey(key);
			setSortAsc(true);
		}
	};

	const sumOfTotalValues = sortable.reduce((sum, item) => sum + (item.totalValue || 0), 0);
	
	sortable.sort((a: EquipmentType, b: EquipmentType) => {
		let x: number | string;
		let y: number | string;
		if (sortKey === 'total') {
			if (isRelative != 'byColumn') {
				x = a.values.reduce((a, b) => a + b.value, 0);
				y = b.values.reduce((a, b) => a + b.value, 0);
			} else {
				x = a.totalValue ? ((a.totalValue / sumOfTotalValues) * 100) : 0;
				y = b.totalValue ? ((b.totalValue / sumOfTotalValues) * 100) : 0;
			}
		} else if (sortKey === 'domains') {
			x = a.block;
			y = b.block;
		} else {
			x = a.values.find(v => v.life_cycle_step_code === sortKey)?.value ?? 0;
			y = b.values.find(v => v.life_cycle_step_code === sortKey)?.value ?? 0;
		}
		if (sortAsc) {
			return x < y ? -1 : x > y ? 1 : 0;
		} else {
			return x > y ? -1 : x < y ? 1 : 0;
		}
	});

	const getUnit = (l: EquipmentType) => {
		if(isRelative) return '%';
		if(isNormalized && !isRelative) return 'PBCI';
		return l.values[0]?.equipment_type_unit;
	};

	/**
	 * @description This component displays the list of equipment and the list of the sum of the impacts associated with it
	 * @constructor
	 * @returns {JSX.Element}
	 * @author Maxime Joly
	 */
	return (
		<table className={'table_overview'}>
			<thead>
				<tr className={'table_overview_head'}>
					{!groupByCategory && <th>{translate('groupby.equipement.name')}</th>}
					{groupByCategory && <th className='groupby_column'>{translate('groupby.equipement.category')}</th>}
					<th className='groupby_column' onClick={() => handleSort('domains')}>
						{translate('groupby.equipement.block')}
						{sortIcon(sortKey === 'domains', sortAsc)}
					</th>
					<th>{translate('groupby.equipement.unit')}</th>
					{
						life_cycle_steps.map((lcs, idx) => (
							<th key={idx} onClick={() => handleSort(lcs)} className={'groupby_column groupby_value'}>
								<div className="groupby_line right">
									{lcs}
									{sortIcon(sortKey === lcs, sortAsc)}
								</div>
							</th>
						))
					}
					<th className={'groupby_column groupby_value groupby_total'} onClick={() => handleSort('total')}>
					Total
						{sortIcon(sortKey === 'total', sortAsc)}
					</th>
				</tr>
			</thead>
			<tbody>
				{
					sortable.map((l, idx) => (
						<tr key={idx} className={idx % 2 == 0 ? 'table_odd' : 'table_even'}>
							{!groupByCategory && <td>{l.values[0]?.equipment_type_short_name} {l.values[0]?.equipment_type_specification}</td>}
							{groupByCategory && <td>{l.values[0]?.equipment_category}</td>}
							<td>{l.values[0]?.block_short_name}</td>
							<td>{getUnit(l)}</td>
							{
								life_cycle_steps.map((lcs, idx) => (
									<td key={idx} className={'groupby_value'}>
										{
											resultValue(l.values.find(v => v.life_cycle_step_code === lcs)?.value) ?? 0
										}
									</td>
								))
							}
							<td className={'groupby_value groupby_total'}>
								{
									isRelative !== 'byColumn'
										? resultValue(l.values.reduce((a, b) => a + b.value, 0))
										: (l.totalValue ? resultValue((l.totalValue / sumOfTotalValues) * 100) : 0)
								}
							</td>
						</tr>
					))
				}
			</tbody>
			{isRelative != 'byRow' && <tfoot>
				<tr>
					<td colSpan={3} className={'groupby_total'}>Total</td>
					{
						life_cycle_steps.map((key, idx) => (
							<td key={idx} className={'groupby_value groupby_total'}>{
								resultValue(sortable.map(x => (x.values.find(v => v.life_cycle_step_code === key)?.value ?? 0)).reduce((a, b) => a + (b ?? 0), 0))
							}</td>
						))
					}
					<td className={'groupby_value groupby_total'}>
						{
							isRelative !== 'byColumn'
								? resultValue(
									sortable.map(x => x.values
										.reduce((a, b) => a + b.value, 0))
										.reduce((a, b) => a + b, 0))
								: 100
						}
					</td>
				</tr>
			</tfoot>}
		</table>
	);
};

/**
 * @description This modal component is used to get the selected studies and companies
 * @constructor
 * @returns {JSX.Element}
 * @author Maxime Joly
 */
const GroupByModal = ({
	done,
	onClose,
	sC,
	sS,
	sD,
	setSC,
	setSS,
	setSD,
	loading,
}: {
	done: (datasets: string[]) => void,
	onClose: () => void,
	sC: string[],
	sS: string[],
	sD: string[],
	setSC: (companies: string[]) => void,
	setSS: (studies: string[]) => void,
	setSD: (datasets: string[]) => void,
	loading: boolean
}): JSX.Element => {
	const { entity: companyEntity } = useCompany();
	const { studies } = useStudies();
	const [companies, setCompanies] = useState<ICompanyManage[]>([]);
	const [selectedCompanies, setSelectedCompanies] = useState<string[]>(sC);
	const [selectedStudies, setSelectedStudies] = useState<string[]>(sS);
	const [selectedDatasetIds, setSelectedDatasetIds] = useState<string[]>(sD);
	const [filterCompanies, setFilterCompanies] = useState<string>('');
	const [filterStudies, setFilterStudies] = useState<string>('');
	const [filterDatasets, setFilterDatasets] = useState<string>('');

	const { datasets } = useDatasets(
		studies?.
			filter(study => selectedStudies.includes(study.id)).
			map(s => s.datasetsId).
			flat().
			filter(Unique)
		?? [],
	);

	const studiesDisplay = studies?.filter(s => selectedCompanies.includes(s.companyFk.id)) || [];
	const datasetsDisplay = datasets?.filter(d => selectedStudies.includes(d.studyId)).flat() || [];

	useEffect(() => {
		companyEntity?.queryCompaniesManagement().then((res) => {
			setCompanies(res);
		}).catch((err) => {
			if (!err) return;
		});
	}, []);


	/**
	 * @description Function to handle the selection of datasets
	 * @param {string} datasetId
	 * @param {boolean} add
	 */
	const handleDatasetSelection = (datasetId: string, add: boolean) => {
		setSelectedDatasetIds(l =>
			add ? [...l, datasetId]
				: l.filter(id => id !== datasetId)
		);
	};

	/**
	 * @description Function to handle the selection of studies
	 * @param {string} studyId
	 * @param {boolean} add
	 * @returns {void}
	 * @author Maxime Joly
	 */
	const handleStudySelection = (studyId: string, add: boolean) => {
		if (!add) {
			const study = studies?.find(s => s.id === studyId);
			if (!study) {
				return;
			}
			datasets?.filter(ds => ds.studyId === studyId).forEach(dataset => {
				handleDatasetSelection(dataset.id, false);
			});
		}
		setSelectedStudies(l =>
			add ? [...l, studyId]
				: l.filter(id => id !== studyId)
		);
	};

	/**
	 * @description Function to handle the selection of companies
	 * @param {string} companyId
	 * @param {boolean} add
	 * @returns {void}
	 * @author Maxime Joly
	 */
	const handleCompanySelection = (companyId: string, add: boolean) => {
		if (!add) {
			const company = companies.find(c => c.id === companyId);
			if (!company) {
				return;
			}
			studies?.filter(s => s.companyFk.id === companyId).forEach(study => {
				handleStudySelection(study.id, false);
			});
		}
		setSelectedCompanies(l =>
			add ? [...l, companyId]
				: l.filter(id => id !== companyId)
		);
	};

	const companyInput = (company: ICompanyManage) => {
		const checked = selectedCompanies.includes(company.id);
		return <div key={company.id}>
			<input
				type="checkbox"
				checked={checked}
				id={`company_${company.id}`}
				onChange={() => handleCompanySelection(company.id, !checked)}
			/>
			<label
				htmlFor={`company_${company.id}`}
			>
				#{company.id} {company.name}
			</label>
		</div>;
	};

	const studyInput = (study: Study) => {
		const checked = selectedStudies.includes(study.id);
		return <div key={study.id}>
			<input
				type="checkbox"
				checked={checked}
				id={`study_${study.id}`}
				onChange={() => handleStudySelection(study.id, !checked)}
			/>
			<label
				htmlFor={`study_${study.id}`}
			>
				#{study.id} {study.name} - {study.year}
			</label>
		</div>;
	};

	const findStudyByDatasetId = (datasetId: string): Study | undefined => {
		return studies?.find(s => s.datasetsId.includes(datasetId));
	};

	const datasetInput = (dataset: StudyDataset) => {
		const checked = selectedDatasetIds.includes(dataset.id);
		return <div key={dataset.id}>
			<input
				type="checkbox"
				checked={checked}
				id={`dataset_${dataset.id}`}
				onChange={() => handleDatasetSelection(dataset.id, !checked)}
			/>
			<label
				htmlFor={`dataset_${dataset.id}`}
			>
				#{dataset.id} {dataset.name} - #{findStudyByDatasetId(dataset.id)?.id}
			</label>
		</div>;
	};

	const handleDone = () => {
		setSC(selectedCompanies);
		setSS(selectedStudies);
		setSD(selectedDatasetIds);
		done(selectedDatasetIds);
	};

	const showFilterCompanies = companies.length > 5;
	const showFilterStudies = studiesDisplay.length > 5;
	const showFilterDatasets = datasetsDisplay.length > 5;

	return (<Modal onClose={onClose}>
		<div className={'modal_content'}>
			<div className="modal_header">
				<h3>{translate('select')}</h3>
			</div>
			<div className="modal_body">
				<div className={'group_by_no_wrap'}>
					<div className={'group_by_selectors'}>
						<h3>{translate('groupby.select.companies')}</h3>
						{showFilterCompanies && <div className={'m2 flex'}>
							<input
								type={'text'}
								value={filterCompanies}
								onChange={(e) => setFilterCompanies(e.target.value)}
							/>
						</div>}
						<div className={'group_by_selection_card'}>
							{companies.filter(c => !filterCompanies.length || c.name.toLowerCase().includes(filterCompanies.toLowerCase())).map(companyInput)}
						</div>
					</div>
					<div className={'group_by_selectors'}>
						<h3>{translate('groupby.select.studies')}</h3>
						{showFilterStudies && <div className={'m2 flex'}>
							<input
								type={'text'}
								value={filterStudies}
								onChange={(e) => setFilterStudies(e.target.value)}
							/>
						</div>}
						<div className={'group_by_selection_card'}>
							{studiesDisplay.filter(s => !filterStudies.length || s.name.toLowerCase().includes(filterStudies.toLowerCase())).map(studyInput)}
						</div>
					</div>
					<div className={'group_by_selectors'}>
						<h3>{translate('groupby.select.datasets')}</h3>
						{showFilterDatasets && <div className={'m2 flex'}>
							<input
								type={'text'}
								value={filterDatasets}
								onChange={(e) => setFilterDatasets(e.target.value)}
							/>
						</div>}
						<div className={'group_by_selection_card'}>
							{datasetsDisplay.filter(d => !filterDatasets.length || d.name.toLowerCase().includes(filterDatasets.toLowerCase())).map(datasetInput)}
						</div>
					</div>
				</div>
			</div>
			<div className={'modal_footer text-right'}>
				<button
					type={'button'}
					onClick={onClose}
				>
					{translate('cancel')}
				</button>
				<ButtonLoading
					classname={selectedDatasetIds.length ? 'button_primary' : 'button_disabled'}
					onClick={handleDone}
					title={'groupby.request.log'}
					loading={loading}
					disable={selectedDatasetIds.length === 0}
				/>
			</div>
		</div>
	</Modal>);
};

function Unique(value: string | undefined, index: number, self: (string | undefined)[]) {
	return value && self.indexOf(value) === index;
}


/**
 * percentageByRow calculates the relative percentage of each value within its row.
 * @param {EquipmentType[]} data - An array of equipment objects. Each object should have a 'values' array containing objects with a 'value' property.
 * @returns {EquipmentType[]} - A new array with the same structure as the input, but with 'value' replaced with its percentage of the total for the row.
 */
const percentageByRow = (data: EquipmentType[]): EquipmentType[] => { 
	// map over each equipment object in the data array
	return data.map(equipment => {
		// calculate the total for the row by reducing over the 'values' array
		const total = equipment.values.reduce((a, b) => a + b.value, 0);
		return {
			...equipment,
			values: equipment.values.map(value => ({
				// for each value object in 'values', keep all the same properties but replace 'value' with its percentage of the total
				...value,
				value: value.value / total * 100
			}))
		};
	});
};

/**
 * percentageByColumn calculates the relative percentage of each value within a "column"
 * @param {EquipmentType[]} data - An array of equipment objects. Each object should have a 'values' array containing objects with a 'value' and 'life_cycle_step_code' property.
 * @returns {EquipmentType[], { [key: string]: number } } - A new array with the same structure as the input, but with 'value' replaced with its percentage of the total for the column.
 */
const percentageByColumn = (data: EquipmentType[]) => {
	// Create an object to store the total value for each column. Columns are identified by 'life_cycle_step_code'.
	const totals: { [key: string]: number } = {};
	// we store the sum of values for each equipment in this object
	const totalValue: { [key: string]: number } = {};
	
	// Iterate over each equipment object in the data array
	data.forEach(equipment => {
		equipment.values.forEach(value => {
			// If this is the first time we've seen this 'life_cycle_step_code', initialize it in the totals object with a value of 0
			if (!totals[value.life_cycle_step_code]) {
				totals[value.life_cycle_step_code] = 0;
			}
			// Add the current value to the total for its 'life_cycle_step_code'
			totals[value.life_cycle_step_code] += value.value;
		});
	});
	return data.map(equipment => {
		totalValue[equipment.id] = equipment.values.reduce((a, b) => a + b.value, 0);
		// return a new object with the same properties as the input, but replace 'values' with a new array
		return {
			...equipment,
			values: equipment.values.map(value => ({
				// for each value object in 'values', keep all the same properties but replace 'value' with its percentage of the total for the column
				...value,
				value: value.value / totals[value.life_cycle_step_code] * 100
			})),
			totalValue: totalValue[equipment.id]
		};
	});
};

export const GroupByBlock = ({
	transformedData
}: {
	transformedData: IResultTableDataOutput[] | Error | null
}): JSX.Element => {
	const [data, setData] = useState<EquipmentType[]>([]);
	const [error, setError] = useState<boolean>(false);
	const [isRelative, setRelative] = useState<boolean | 'byRow' | 'byColumn'>(false);
	const [relativeData, setRelativeData] = useState<EquipmentType[]>([]);
	const [life_cycle_steps, setLifeCycleSteps] = useState<string[]>([]);
	const [indicators, setIndicators] = useState<string[]>([]);
	const [sortIndicator, setSortIndicator] = useState<string>('');
	const [filterDomain, setFilterDomain] = useState<string>('');
	const [groupByCategory, setGroupByCategory] = useState<boolean>(true);
	const [tooltip, setTooltip] = useState<boolean | 'lifeCycleStep' | 'equipment'>(false);
	const [tooltipPosition, setTooltipPosition] = useState<number[]>([0, 0]);
	const domains = useMemo(() => data.map(e => e.block).filter(Unique), [data]);
	const [isNormalized, setIndicatorNormalized] = useState<boolean>(false);

	const { data: logged } = useUser();
	const forceGroupByCategory = !logged?.permissions.includes('login.can_view_equipment_groupby_all');

	useEffect(() => {
		if (forceGroupByCategory) {
			setGroupByCategory(true);
		}
	}, []);

	useEffect(() => {
		if (transformedData instanceof Error) {
			adapter.storeNotification({
				id: uuid(),
				message: translate('groupby.error') as string,
				type: 'error'
			});
		} else {
			if(transformedData) {
				const mappedData: EquipmentType[] = transformedData.map((data: IResultTableDataOutput) => {
					return {
						id: data?.equipment_type?.id ?? '',
						block: data?.block ?? '',
						unit: data?.unit ?? '',
						category: data?.category ?? '',
						totalValue: data?.totalValue ?? 0,
						values: [{
							equipment_type_id: data?.equipment_type?.id ?? '',
							equipment_type_name: data?.equipment_type?.name ?? '',
							equipment_type_short_name: data?.equipment_type?.shortName ?? '',
							equipment_type_specification: data?.equipment_type?.specification ?? '',
							equipment_type_unit: data?.unit ?? '',
							indicator_short_name: data?.indicator ?? '',
							life_cycle_step_code: data?.life_cycle_step ?? '',
							block_short_name: data?.block ?? '',
							equipment_category: data?.category ?? '',
							value: data?.value ?? 0
						}]
					};
				});
				const list : EquipmentType[] = [];

				for (const elem of mappedData) {
					const finder = list.find((e) => (
						e.id === elem.id &&
						e.block === elem.block &&
						e.category === elem.category
					));
					if (finder) {
						finder.values.push(...elem.values);
					} else {
						list.push({
							id: elem.id || '',
							unit: elem.unit || '',
							block: elem.block || '',
							category: elem.category || '',
							values: elem.values
						});
					}
				}

				// create a list of life cycle steps with unique entries of life cycle steps
				const listLCS = list.map((e) => e.values.map((v) => v.life_cycle_step_code)).flat().filter(Unique);
				const indicatorsList = list.map((e) => e.values.map((v) => v.indicator_short_name)).flat().filter(Unique);
				indicatorsList.sort((a: string, b: string) => {
					if (a === 'normalized' && b === 'normalized')
						return 0;
					if (a === 'normalized')
						return -1;
					if (b === 'normalized')
						return 1;
					return 0;
				});
				setData(list);
				setLifeCycleSteps(listLCS);
				setIndicators(indicatorsList);
				if (sortIndicator == null || !indicatorsList.includes(sortIndicator)) setSortIndicator(indicatorsList[0]);
			}
		}
	}, [transformedData]);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSortIndicator(e.target.value);
		if (e.target.value === 'normalized') return setIndicatorNormalized(true);
		return setIndicatorNormalized(false);
	};

	const dataSorted : EquipmentType[] = useMemo(() => {
		let d = data;
		if (groupByCategory) {
			d = [];
			for (const e of data) {
				const f = d.find(el => el.category === e.category && el.block === e.block);
				if (f) {
					for (const v of f.values) {
						const ev = e.values.find(el =>
							el.life_cycle_step_code === v.life_cycle_step_code && el.indicator_short_name === v.indicator_short_name
						);
						if (ev) {
							v.value += ev.value;
						}
					}
				} else {
					d.push({ ...e, values: e.values.map(v => ({ ...v, value: v.value })) });
				}
			}
		}

		return d.filter(e => filterDomain === '' || e.block === filterDomain).map(e => {
			const newValues = e.values.filter(v => v.indicator_short_name === sortIndicator);
			return {
				...e,
				values: newValues
			};
		});
	}, [data, groupByCategory, filterDomain, sortIndicator]);

	useEffect(() => {
		if (isRelative === 'byRow') {
			setRelativeData(percentageByRow(dataSorted));
		} else if (isRelative === 'byColumn') {
			setRelativeData(percentageByColumn(dataSorted));
		}
	}, [isRelative, dataSorted]);

	const exports = () => {
		const d = !isRelative ? dataSorted : relativeData;
		const csv = [
			`${!groupByCategory ? translate('groupby.equipment.name') : translate('groupby.equipment.category')},${translate('groupby.equipment.block')},${translate('groupby.equipment.unit')},${life_cycle_steps.join(',')},${translate('groupby.equipment.total')}`,
			...d.map(e => {
				let equipment = !groupByCategory ? `${e.values[0]?.equipment_type_short_name} ${e.values[0]?.equipment_type_specification}` : e.values[0]?.equipment_category;
				equipment = equipment.replace(/,/g, '');
				const block = e.values[0]?.block_short_name;
				const unit = isRelative ? '%' : isNormalized ? 'PBCI' : e.values[0]?.equipment_type_unit;
				const values = life_cycle_steps.map(lcs => resultValue(e.values.find(v => v.life_cycle_step_code === lcs)?.value) ?? 0);
				const sumOfTotalValues = d.reduce((sum, item) => sum + (item.totalValue || 0), 0);
				const total = isRelative !== 'byColumn'
					? resultValue(e.values.reduce((a, b) => a + b.value, 0))
					: (e.totalValue ? resultValue((e.totalValue / sumOfTotalValues) * 100) : 0);
				return `${equipment},${block},${unit},${values.map(v => v.replace(/,/g,'.')).join(',')},${`${total || 0}`.replace(/,/g,'.')}`;
			})
		].join('\n');
		const blob = new Blob([csv], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.click();
	};

	const dataFilled = data.length > 0;
	if (error) return <ErrorImage errorText={translate('groupby.error') as string} img='bug_fixing_man' modal={true} onClose={()=> setError(false)}/>;
	if (!dataFilled) return <></>;
	return (
		<>
			<div className={'m1'}>
				{tooltip && <ToolTipLight
					style={{ left: tooltipPosition[0], top: tooltipPosition[1] }}
					text={translate(`groupby.tooltip.${tooltip}`) as string}
				/>}
				<div className="groupby_line">
					<div className={'group_by_selects'}>
						{!forceGroupByCategory ? (
							<select
								value={groupByCategory ? 'true' : 'false'}
								onChange={(e) => setGroupByCategory(e.target.value === 'true')}
							>
								<option value={'true'}>{translate('groupby.grouped.category')}</option>
								<option value={'false'}>{translate('groupby.grouped.equipment')}</option>
							</select>
						) : (
							<input
								value={translate('groupby.grouped.category') as string}
								disabled={true}
							/>
						)}
						<select
							value={filterDomain}
							onChange={(e) => setFilterDomain(e.target.value)}
						>
							<option value={''}>{translate('groupby.allDomains')}</option>
							{domains.map((i) => <option key={`d${i}`} value={i}>{i}</option>)}
						</select>
						<select
							value={sortIndicator}
							onChange={(e) => handleChange(e)}
						>
							{indicators.map((i) =>
								<option key={`i${i}`} value={i}>
									{i === 'normalized' ? translate('dashboard.select.allIndicatorsGrouped') as string : i}
								</option>
							)}
						</select>
					</div>
					<button className={`group_by_percentage_button${!isRelative ? ' selected' : ''}`}
						onClick={() => setRelative(false)}
					>
						<i className="fa-regular fa-table" />
					</button>
					{!forceGroupByCategory && <button
						className={`group_by_percentage_button${isRelative === 'byRow' ? ' selected' : ''}`}
						onMouseEnter={(e) => {
							setTooltip('lifeCycleStep');
							setTooltipPosition([e.clientX, e.clientY]);
						}}
						onMouseLeave={() => setTooltip(false)}
						onClick={() => setRelative('byRow')}
					>
						<i className="fa-regular fa-percent" />
						<i className="fa-regular fa-arrows-spin" />
					</button>}
					<button className={`group_by_percentage_button${isRelative === 'byColumn' ? ' selected' : ''}`}
						onMouseEnter={(e) => {
							setTooltip('equipment');
							setTooltipPosition([e.clientX, e.clientY]);}
						}
						onMouseLeave={() => setTooltip(false)}
						onClick={() => setRelative('byColumn')}
					>
						<i className="fa-regular fa-percent" />
						<i className="fa-regular fa-desktop" />
					</button>
					<button
						type={'button'}
						className={'group_by_percentage_button selected'}
						onClick={exports}
					>
						<i className="fa-regular fa-file-export" />
					</button>
				</div>
			</div>
			<div className={'m1'}>
				<GroupByTable
					data={!isRelative ? dataSorted : relativeData}
					groupByCategory={groupByCategory}
					life_cycle_steps={life_cycle_steps}
					isRelative={isRelative}
					isNormalized={isNormalized}
				/>
			</div>
		</>);
};

const GroupByResult = (): JSX.Element => {
	const [error, setError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState(false);
	const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
	const [selectedStudies, setSelectedStudies] = useState<string[]>([]);
	const [selectedDatasetIds, setSelectedDatasetIds] = useState<string[]>([]);
	const [dataEquipment, setDataEquipment] = useState<IResultTableDataInput[]>([]);
	const transformedData = useTransformedResultTableData(dataEquipment, true);

	const { studies } = useStudies();
	const { datasets } = useDatasets(selectedDatasetIds);
	const { entity: resultsEntity } = useResults();

	/**
	 * @description Function to handle the selection of dataset ids
	 * @returns {void}
	 * @author Maxime Joly
	 */
	const handleRequestEquipment = (datasets: string[]) => {
		setLoading(true);
		const input = { datasets: datasets };
		resultsEntity?.queryEquipmentGroupBy(input).then((res) => {
			const stringResult = res.equipmentGroupby.json;
			const result = JSON.parse(stringResult);
			const jsonResult: IResultTableDataInput[] = JSON.parse(result);
			setLoading(false);
			if (jsonResult.length === 0) {
				setError(true);
				return;
			}
			setDataEquipment(jsonResult);
			setOpenModal(false);
		}).catch(() => {
			setError(true);
			setLoading(false);
		});
	};

	const datasetsNames = selectedDatasetIds.map(id => {
		const study = studies?.find(s => s.datasetsId.includes(id));
		const company = study?.companyFk;
		const dataset = datasets.find(d => d.id === id);
		return `${company?.name ?? '?'} - ${study?.name ?? '?'} - ${dataset?.name}`;
	});

	if (error) return <ErrorImage errorText={translate('groupby.error') as string} img='bug_fixing_man' modal={true} onClose={()=> setError(false)}/>;
	const dataFilled = Array.isArray(transformedData) && transformedData.length > 0;
	return (
		<>
			{dataFilled && <Tip type={'info'} style={{ width: '98%' }}>
				<>
					{translate('groupby.tipSelectedDatasets')}
					<ul>
						{datasetsNames.map((name, idx) => <li key={idx}>{name}</li>)}
					</ul>
				</>
			</Tip>}
			<div className={'m1'}>
				{openModal && <GroupByModal
					done={handleRequestEquipment}
					onClose={() => setOpenModal(false)}
					sC={selectedCompanies}
					sS={selectedStudies}
					sD={selectedDatasetIds}
					setSC={setSelectedCompanies}
					setSS={setSelectedStudies}
					setSD={setSelectedDatasetIds}
					loading={loading}
				/>}
				<div className="groupby_line">
					<button
						type={'button'}
						className={'button_primary'}
						onClick={() => setOpenModal(true)}
					>
						{translate('select')}
					</button>
				</div>
			</div>
			{loading && <Spinner/>}
			<GroupByBlock
				transformedData={transformedData}
			/>
		</>);
};

export default GroupByResult;