import { gql } from '@apollo/client';

export const QUERY_COMPANIES_TO_MANAGE = gql`
	query QUERY_COMPANIES_TO_MANAGE {
		companiesToManage {
			id
			name
			emailDomains
			rdbSecretAccessToken
			start
			end
			isCguAccepted
		}
	}
`;

