import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import './Notifications.scss';
import { translate, TranslateChange } from '../../../infrastructure/translations/translate';
import { ApiErrorJson, ApiErrorVariableStyle } from '../../../domain/interfaces/ApiErrorJson';
import { removeNotification } from '../../../store/dispatchers';
import { useNavigate } from 'react-router-dom';
import { INotification } from '../../../domain/interfaces/INotification';
import { isApiErrorJson } from '../../../utils/isApiErrorJson';
import { EMERGENCY_MAIL, SUPPORT_MAIL } from '../../../config';

const styleWrapper = (content: string, style: ApiErrorVariableStyle | undefined, href?: string): React.ReactElement => {
	switch (style) {
		case undefined:
			return <>{content}</>;
		case 'bold':
			return <strong>{content}</strong>;
		case 'underline':
			return <u>{content}</u>;
		case 'italic':
			return <i>{content}</i>;
		case 'link':
			return href ? <a href={href}>{content}</a> : <>{content}</>;
		case 'translate':
			return <>{translate(content)}</>;
		default:
			return <>{content}</>;
	}
};

export const translateFromJSON = (json: ApiErrorJson) => {
	const changes: TranslateChange = {};

	if (json.variables) {
		json.variables.forEach(variable => {
			changes[variable.key] = () => styleWrapper(variable.value, variable.style, variable.href);
		});
	}

	// Add the support mail to the changes
	changes['emergency'] = () => <a href={`mailto:${EMERGENCY_MAIL}`}>{EMERGENCY_MAIL}</a>;
	changes['support'] = () => <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>;
	changes['break'] = () => <br/>;

	return translate(json.key, changes) as React.ReactElement;
};

const iconMap = {
	success: 'fa-check-circle',
	info: 'fa-info-circle',
	warning: 'fa-exclamation-circle',
	error: 'fa-exclamation-triangle',
};


const Notification = ({ notification }: { notification: ApiErrorJson | INotification}) => {
	const navigate = useNavigate();
	const [isVisible, setIsVisible] = useState(true);

	const handleClose = () => {
		setIsVisible(false);
		// Wait for animation to complete before removing notification
		setTimeout(() => {
			removeNotification(notification);
		}, 300);
	};

	const handleRedirect = () => {
		if (!notification.href) return;
		navigate(notification.href || '');
		handleClose();
	};

	return (
		<div className={`notification notification--${notification.type} ${!isVisible ? 'hidden' : ''}`}>
			<div className="notification__content">
				<div className="notification__icon-wrapper">
					<i className={`notification__icon fas ${iconMap[notification.type]}`}/>
				</div>
				<div className="notification__body">
					<p className="notification__message">
						{isApiErrorJson(notification)
							? translateFromJSON(notification)
							: notification.message
						}
					</p>
				</div>
				<div className="notification__actions">
					{notification.href && (
						<button
							type="button"
							className="notification__action-btn"
							onClick={handleRedirect}
							aria-label={translate('goTo') as string}
							title={translate('goTo') as string}
						>
							<i className="fas fa-external-link-alt"/>
						</button>
					)}
					<button
						type="button"
						className="notification__action-btn notification__close"
						onClick={handleClose}
						aria-label="Close"
					>
						<i className="fas fa-times"/>
					</button>
				</div>
			</div>
		</div>
	);
};

const Notifications = () => {
	const notifications = useSelector((state: State) => state.notifications);

	if (!notifications || notifications.length === 0) {
		return <></>;
	}

	return (
		<div className="notifications-container">
			{notifications.map((notification, index) => {
				return (
					<div key={`notification_${index}`}>
						<Notification notification={notification}/>
					</div>
				);
			})}
		</div>
	);
};

export default Notifications;