import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ITokenAuthData } from '../../domain/interfaces/ITokenAuthData';
import { ICredentials } from '../../domain/interfaces/ICredentials';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IUserPasswords } from '../../domain/interfaces/IUserPasswords';
import { IUserPasswordsResponse } from '../../domain/interfaces/IUserPasswordsResponse';
import { IUserPasswordResetResponse } from '../../domain/interfaces/IUserPasswordResetResponse';
import { IUserPasswordReset } from '../../domain/interfaces/IUserPasswordReset';
import { IUserPasswordSendResetResponse } from '../../domain/interfaces/IUserPasswordSendResetResponse';
import { IUserPasswordSendReset } from '../../domain/interfaces/IUserPasswordSendReset';
import { ResponseUserAssignments } from '../../domain/interfaces/ResponseUserAssignments';
import { IQueryUserConnectedVerifyOutput } from '../../domain/interfaces/IQueryUserConnectedVerify';
import { ResponseUser } from '../../domain/interfaces/ResponseUserData';
import { IQueryLogoutErrorTestOutput } from '../../domain/interfaces/IQueryLogoutErrorTest';
import { IMutateSocialAuthenticationInput, IMutateSocialAuthenticationOutput } from '../../domain/interfaces/IMutateSocialAuthentication';
import { IQueryGroupsResponse } from '../../domain/interfaces/IQueryGroups';
import { ResponseErrorReport } from '../../domain/data/ResponseErrorReport';
import { ErrorReport } from '../../domain/data/entries/ErrorReport';
import { IQueryUpdateOutput } from '../../domain/interfaces/IQueryUpdate';
import { IMutateUserManagementResponse } from '../../domain/interfaces/IMutateUserManagement';
import { IUserManagement } from '../../domain/interfaces/ResponseCompanyUsers';
import { IUserAssignmentMutate } from '../../domain/interfaces/IUserAssignment';
import { IMutateUserAssignmentsOutput } from '../../domain/interfaces/IMutateUserAssignmentsOutput';
import { IAcceptCgu, IAcceptCguResponse } from '../../domain/interfaces/IMutateAcceptCgu';
import { IMutateUserHasSeenUpdateOutput } from '../../domain/interfaces/IMutateUserHasSeenUpdate';
import {
	IMutateSendSuggestionInput,
	IMutateSendSuggestionOutput
} from '../../domain/interfaces/IMutateSendSuggestion';
import { IMutateUserRemoveAccount } from '../../domain/interfaces/IMutateUserRemoveAccount';

export class ApolloClientMutateLoginUser extends ApolloClientProvider<ITokenAuthData, ICredentials> {
	exec({ token, lang, adapter }: IAPIProps, data: ICredentials): Promise<ITokenAuthData | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateAcceptCgu extends ApolloClientProvider<IAcceptCguResponse, IAcceptCgu> {
	exec({ token, lang, adapter }: IAPIProps, data: IAcceptCgu): Promise<IAcceptCguResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateUserPassword extends ApolloClientProvider<IUserPasswordsResponse, IUserPasswords> {
	exec({ token, lang, adapter }: IAPIProps, data: IUserPasswords): Promise<IUserPasswordsResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateUserPasswordReset extends ApolloClientProvider<IUserPasswordResetResponse, IUserPasswordReset> {
	exec({ token, lang, adapter }: IAPIProps, data: IUserPasswordReset): Promise<IUserPasswordResetResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateUserPasswordSendReset extends ApolloClientProvider<IUserPasswordSendResetResponse, IUserPasswordSendReset> {
	exec({ token, lang, adapter }: IAPIProps, data: IUserPasswordSendReset): Promise<IUserPasswordSendResetResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryUserAssignments extends ApolloClientProvider<ResponseUserAssignments, { lineId: string }> {
	exec({ token, lang, adapter }: IAPIProps, data: { lineId: string }): Promise<ResponseUserAssignments | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateUserAssignments extends ApolloClientProvider<IMutateUserAssignmentsOutput, { userAssignments: IUserAssignmentMutate[] }> {
	exec({ token, lang, adapter }: IAPIProps, data: { userAssignments: IUserAssignmentMutate[] }): Promise<IMutateUserAssignmentsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateSocialAuthentication extends ApolloClientProvider<IMutateSocialAuthenticationOutput, IMutateSocialAuthenticationInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateSocialAuthenticationInput): Promise<IMutateSocialAuthenticationOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateReportError extends ApolloClientProvider<ResponseErrorReport, ErrorReport> {
	exec({ token, lang, adapter }: IAPIProps, data: ErrorReport): Promise<ResponseErrorReport | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateSendSuggestion extends ApolloClientProvider<IMutateSendSuggestionOutput, IMutateSendSuggestionInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateSendSuggestionInput): Promise<IMutateSendSuggestionOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateUserManagement extends ApolloClientProvider<IMutateUserManagementResponse, IUserManagement> {
	exec({ token, lang, adapter }: IAPIProps, data: IUserManagement): Promise<IMutateUserManagementResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateUserHasSeenUpdate extends ApolloClientProvider<IMutateUserHasSeenUpdateOutput> {
	exec({ token, lang, adapter }: IAPIProps): Promise<IMutateUserHasSeenUpdateOutput | null | undefined> {
		return this.get({ token, lang, adapter });
	}
}

export class ApolloClassClientMutateUserRemoveAccount extends ApolloClientProvider<IMutateUserRemoveAccount> {}

export class ApolloClientQueryUpdate extends ApolloClientProvider<IQueryUpdateOutput> {}
export class ApolloClientQueryUserConnectedVerify extends ApolloClientProvider<IQueryUserConnectedVerifyOutput> {}
export class ApolloClientQueryUserConnected extends ApolloClientProvider<ResponseUser> {}
export class ApolloClientQueryLogoutError extends ApolloClientProvider<IQueryLogoutErrorTestOutput> {}
export class ApolloClientQueryGroups extends ApolloClientProvider<IQueryGroupsResponse> {}
