import React from 'react';
import './ResultSkeleton.scss';

export const ChartSkeleton = () => {
	return (
		<div className={'skeleton_block'}>
			<div className='skeleton_header'/>
			<div className='skeleton_chart'>
				<div className='chart_container'>
					{[...Array(20)].map((_, index) => (
						<div
							key={index}
							className='chart_bar'
							style={{
								height: `${Math.random() * 40 + 60}%`  // Random height between 60% and 100%
							}}
						/>
					))}
				</div>
			</div>
		</div>
	);
};