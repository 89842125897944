import React from 'react';
import './ResultSkeleton.scss';

export const TableSkeleton = () => {
	return (
		<div className={'skeleton_block'}>
			<div className={'skeleton_header'} />
			<div className={'skeleton_table'}>
				{[...Array(8)].map((_, index) => (
					<div key={index} className='skeleton_row'>
						<div className={`skeleton_cell ${index === 0 ? 'header' : ''} title`} />
						{[...Array(4)].map((_, cellIndex) => (
							<div
								key={cellIndex}
								className={`skeleton_cell ${index === 0 ? 'header' : ''} data`}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	);
};