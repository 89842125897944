import { useEffect, useState } from 'react';
import { IResultTableDataInput, IResultTableDataOutput, transformResultTableData } from './getResultTableData';
import { useBlocks } from '../viewComponents/hooks/useBlocks';
import { useIndicators } from '../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../viewComponents/hooks/useLifeCycleSteps';
import { useMaturity } from '../viewComponents/hooks/useMaturity';
import { useManageEquipment } from '../viewComponents/hooks/useManageEquipment';


/**
 * @description This hook is used to transform the result data from ID's to names and units.
 * 
 * @param {IResultTableDataInput[] | null} data the result data to transform
 * @param {boolean} isNormalize if the data is normalized or not. If it's true we use all indicators as the normalized result is calculated on all indicators.
 * @returns {IResultTableDataOutput[] | null | Error} the transformed with the names and units instead of ID's
 * @author Yacine Bentayeb
 */
export const useTransformedResultTableData = (data: IResultTableDataInput[] | null, isNormalize = false, isMaturity = false) => {
	if (!data) return null;
	const [transformedData, setTransformedData] = useState<IResultTableDataOutput[] | null | Error>(null);
	const { blocks } = useBlocks();
	const { entity: indicatorsEntity, selectedIndicators, indicators } = useIndicators();
	const { lcs } = useLifeCycleSteps();
	const { entity: maturityEntity } = useMaturity();
	const { entity: manageEquipment } = useManageEquipment();

	useEffect(() => {
		if (
			// Here we check if the data is empty or the blocks or the indicators or the life cycle steps are empty
			// If it's a maturity result we don't need to check for theses
			(!(blocks.length > 0)
			|| !(isNormalize ? indicators.length > 0 : selectedIndicators.length > 0)
			|| !(lcs.length > 0)) && !isMaturity
		) return;
		const fetchData = async () => {
			const result = await transformResultTableData(data, blocks, isNormalize ? indicators : selectedIndicators, lcs, maturityEntity, indicatorsEntity, manageEquipment);
			setTransformedData(result);
		};
		fetchData();
	}, [JSON.stringify({ data, blocks, selectedIndicators, indicators, lcs })]);

	return transformedData;
};
