import { gql } from '@apollo/client';

export const MUTATE_FILTER_INDICATORS = gql`
    mutation MutateFilterIndicators(
        $datasetId: String!,
        $indicatorIds: [String]!
    ) {
        mutateFilterIndicators(
			datasetId: $datasetId,
			indicatorIds: $indicatorIds
        ) {
            dataset {
                id,
                name,
                desc,
                archived,
                studyId,
                labels,
                status {
                    maturity,
                    inventory,
                    validated,
                    results
                    impactsComplete
                },
                metadata {
                    prioEquipRate,
                    equipmentRate,
                    prioSettingRate,
                    settingRate,
                    maturityRate
                },
                permissions {
                    collectOpen,
                    validationOpen,
                    validate,
                    results
                },
                selectedIndicators
            	hasDefinitiveResults
            	hasSettings
            }
        }
    }
`;
