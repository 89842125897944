import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Plugin } from 'chart.js';
import { GRAPH_COLORS } from '../../../config';
import { ScriptableScaleContext } from 'chart.js/dist/types';

function drawText(ctx: CanvasRenderingContext2D, text: string, x: number, y: number) {
	ctx.fillStyle = 'black';
	ctx.font = 'bold 12px Arial';
	ctx.fillText(text, x - (ctx.measureText(text).width / 2), y);
}

const displayArrow = (ctx: CanvasRenderingContext2D, arrowX: number, arrowY: number, arrowBaseY: number, isPositive: boolean): void => {
	const arrowHeight = 5;
	const arrowWidth = 20;
	ctx.fillStyle = isPositive ? GRAPH_COLORS.LIGHT_GREEN : GRAPH_COLORS.RED;
	// draw the arrow
	ctx.beginPath();
	ctx.moveTo(arrowX, arrowY + (isPositive ? -7 : 7));
	if (isPositive) {
		ctx.lineTo(arrowX - arrowWidth, arrowY + arrowHeight + 10);
		ctx.lineTo(arrowX, arrowBaseY + 3);
		ctx.lineTo(arrowX + arrowWidth, arrowY + arrowHeight + 10);
	} else {
		ctx.lineTo(arrowX - arrowWidth, arrowY - arrowHeight - 10);
		ctx.lineTo(arrowX, arrowBaseY - 3);
		ctx.lineTo(arrowX + arrowWidth, arrowY - arrowHeight - 10);
	}
	ctx.closePath();
	ctx.fill();
};


/**
 * @param values : Number[] , The values to display 
 * @param labels : String[] , The labels to display
 * @description : Display a bar chart with arrows on top of the bars
 * The arrows are pointing up if the value is positive and down if the value is negative
 * @returns 
 */
export const ArrowBarChart = ({ values, labels }: { values: number[], labels: string[] }): JSX.Element => {
	const green = GRAPH_COLORS.LIGHT_GREEN;
	const red = GRAPH_COLORS.RED;
	const maxDiff = Math.max(...values.map(value => Math.abs(value)));
	values[1] = -20;
	const stepSize = 20;

	// Get the max value to display on the y axis
	const adjustedMax = Math.ceil(maxDiff/stepSize) * stepSize;
		
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const plugins: Plugin<'bar', any>[] = [{
		id: 'Display arrows on top of the bars',
		beforeDraw: function(chart) {
			const { ctx } = chart;
			const bars = chart.getDatasetMeta(0).data;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			bars.forEach((bar: any, index) => {
				const textPercentage = values[index] ? values[index] + '%' : '';
				const isPositive = !!(values[index] && values[index] > 0);
				const x = bar.x;
				const y = bar.y;
				const baseBar = bar.base;
				drawText(ctx, textPercentage, x, (isPositive ? baseBar + 12 : baseBar - 6));

				const arrowX = x;
				const arrowHeight = 3;
				const arrowY = y + (isPositive ? - arrowHeight : + arrowHeight);
				ctx.fillStyle = isPositive ? green : red;
				displayArrow(ctx, arrowX, arrowY, y, isPositive);
			});
		}
	}];

	const datasets = [
		{
			label: 'bellow average',
			data: values,
			backgroundColor: values.map(value => value && value < 0 ? red : green),
			borderRadius: 20,
		}
	];

	const dataChart = {
		labels: labels,
		datasets: datasets,
	};

	return <Bar
		data={dataChart}
		plugins={plugins}
		options={
			{
				plugins: {
					legend: {
						display: false,
					},
				},
				responsive: true,
				scales: {
					y: {
						min: -(adjustedMax),
						max: adjustedMax,
						ticks: {
							stepSize: stepSize,
						},
						grid:{
							color: function(context: ScriptableScaleContext) {
								if (context.tick.value == 0)
									return 'grey';
								else 
									return 'rgba(0,0,0,0)';
							}
						}
					}
				},
				datasets: {
					bar: {
						barThickness: 20,
					},
				},
			}
		}
	/>;
};
