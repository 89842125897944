import { gql } from '@apollo/client';

export const QUERY_CMDB = gql`
    query QueryCmdb (
    	$datasetId: String!
    	$cmdbName: String!
    	$cmdbCategory: String
    	$blockName: String
    )
    {
    	cmdb (
    	 	datasetId: $datasetId
    	 	cmdbName: $cmdbName
    	 	cmdbCategory: $cmdbCategory
    	 	blockName: $blockName
		) {
			id,
			name,
			category,
			categories {
				identifier,
				name,
			},
			block,
			dataset,
    	}
    }
`;
