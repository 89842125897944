import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { IResultTableProps, ResultTable } from './ResultTable';
import Spinner from '../utils/Spinner/Spinner';
import { ArrowBarChart } from '../graphs/bars/ArrowBarChart';

export type ResultMaturityComparisonByDomainType = { schema: { fields: {name: string, type: string}[], primaryKey: string[], pandas_version: string }, data: { domain: string, scope: string, grade: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultMaturityComparisonByDomain = (o: any): o is ResultMaturityComparisonByDomainType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultMaturityComparisonByDomain = ({ data }: { data: string }): JSX.Element => {
	if (!isResultMaturityComparisonByDomain(data)) return (<ResultCardError/>);

	// transform the data from id to name
	const transformedData = useTransformedResultTableData(data.data, false, true);
	const [tableData, setTableData] = useState<IResultTableProps | null>(null);

	const getGrade = (grade: number) => {
		if (grade <= 1) return translate('results.maturity.initial') as string;
		if (grade <= 2) return translate('results.maturity.reproducible') as string;
		if (grade <= 3) return translate('results.maturity.defined') as string;
		if (grade <= 4) return translate('results.maturity.mastered') as string;
		if (grade <= 5) return translate('results.maturity.optimized') as string;
		return '';
	};

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// Prepare the data for the table
		setTableData({
			columns: [{ name: translate('results.domain') as string },
				{ name: translate('results.scope') as string },
				{ name: translate('results.value') as string },
				{ name: translate('results.grade') as string },
			],
			values: transformedData.map(
				(d) => [
					{ value: d.domain, valueType: 'left' },
					{ value: d.scope ?? ' ', valueType: 'right' },
					{ value: (d.grade as number / 5 * 100).toFixed(0) + '%', valueType: 'right' },
					{ value: getGrade(d.grade as number), valueType: 'right' },
				]
			),
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!tableData) return <Spinner/>;
	return <ResultTable dataTable={tableData} />;
};

export const MaturityComparisonByDomainGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultMaturityComparisonByDomain(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data, false, true);
	if (transformedData instanceof Error || !transformedData) return <ResultCardError/>;
	if (transformedData[0].mean === undefined) return <ResultCardError errorMsg={translate('results.error.needComparison') as string}/>;

	const labels = transformedData.map(d => String(d.domain));
	const diffPercentage = transformedData.map((d) => {
		return Math.floor((Number(d.grade) - Number(d.mean)) / Number(d.mean) * 100);
	});
	return <ArrowBarChart labels={labels} values={diffPercentage} />;
};