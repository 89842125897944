import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import 'chart.js/auto';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { IResultTableProps, ResultTable } from './ResultTable';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { resultValue } from './utils/resultValue';
import { BarChart } from '../graphs/bars/BarChart';
import { ResultArrowComparison, ResultComparison } from './ResultArrowComparison';

export type ResultMaturityType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { domain: string, scope: string, grade: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultMaturity = (o: any): o is ResultMaturityType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultMaturity = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultMaturity(data)) return (<ResultCardError/>);

	const transformedData = useTransformedResultTableData(data.data, false, true);
	const [tableData, setTableData] = useState<IResultTableProps | null>(null);

	const getGrade = (grade: number) => {
		if (grade < 2) return translate('results.maturity.initial') as string;
		if (grade < 3) return translate('results.maturity.reproducible') as string;
		if (grade < 4) return translate('results.maturity.defined') as string;
		if (grade < 5) return translate('results.maturity.mastered') as string;
		if (grade == 5) return translate('results.maturity.optimized') as string;
		return '';
	};

	// for relative data
	const getGradeRelative = (grade: number) => {
		if (grade < 40) return translate('results.maturity.initial') as string;
		if (grade < 60) return translate('results.maturity.reproducible') as string;
		if (grade < 80) return translate('results.maturity.defined') as string;
		if (grade < 100) return translate('results.maturity.mastered') as string;
		if (grade == 100) return translate('results.maturity.optimized') as string;
		return '';
	};
	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		setTableData( {
			columns: [{ name: translate('results.domain') as string },
				{ name: translate('results.value') as string },
				{ name: translate('results.grade') as string },
			],
			values: transformedData.map(d => [
				{ value: d.domain, valueType: 'left' },
				{ value: (
					<>
						{`${resultValue(d.grade)}${isRelative ? '%' : ''}`}
						<ResultArrowComparison
							arrow={
								ResultComparison({
									value: d.grade as number,
									mean: d.mean,
									max: d.max,
									min: d.min
								})
							}
							maturity={true}
						/>
					</>
				), valueType: 'right' },
				// if the data are relative, we use getGradeRelative
				{ value: d.grade as number > 5 ? getGradeRelative(d.grade as number) : getGrade(d.grade as number), valueType: 'right' },
			]),
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!tableData) return <Spinner/>;
	return <ResultTable dataTable={tableData} />;
};

export const MaturityGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultMaturity(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data, false, true);
	if (transformedData instanceof Error || !transformedData) return <ResultCardError/>;

	const barData = transformedData.map(d => ({ label: String(d.domain), value: Number(d.grade), scope:String(d.scope) }));
	barData[barData.length - 1].label = translate('results.maturity.averageScore') as string;

	if (barData.some(d => d.label.length > 30))
		barData.forEach(d => {
			if (d.label.length > 30)
				d.label = d.label.slice(0, 27).trim().concat('...');
		});
	return <BarChart barData={barData} minY={1} maxY={5} labelLength={30} maxBarWidth={45} legend={translate('results.maturity.score') as string}/>;
};
