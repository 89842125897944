import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import {
	IMutateAddEquipmentExploreInput,
	IMutateAddEquipmentExploreOutput
} from '../../domain/interfaces/IMutateAddEquipmentExplore';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import {
	IMutateAddEquipmentFromCmdbInput,
	IMutateAddEquipmentFromCmdbOutput
} from '../../domain/interfaces/IMutateAddEquipmentFromCmdb';
import { IMutateManageCmdbInput, IMutateManageCmdbOutput } from '../../domain/interfaces/IMutateManageCmdb';
import { IMutateEquipmentEditInput, IMutateEquipmentEditResponse } from '../../domain/interfaces/IMutateEquipmentEdit';
import {
	IMutateAddEquipmentResilioDbInput,
	IMutateAddEquipmentResilioDbOutput
} from '../../domain/interfaces/IMutateAddEquipmentResilioDb';
import { IQueryCmdbEquipmentsInput, IQueryCmdbEquipmentsOutput } from '../../domain/interfaces/IQueryCmdbEquipments';
import { IQueryCmdbTemplateInput, IQueryCmdbTemplateOutput } from '../../domain/interfaces/IQueryCmdbTemplate';
import { IQueryCheckResilioDbStatusOutput } from '../../domain/interfaces/IQueryCheckResilioDbStatus';
import { ResponseDataInventory } from '../../domain/data/ResponseDataInventory';
import { ResponseMutateLineInventorySave } from '../../domain/data/ResponseDatas';
import { DataMutate } from '../../domain/core/Entity';
import { Equipment } from '../../domain/data/entries/Equipment';
import {
	IMutateExportInventoryInput,
	IMutateExportInventoryOutput
} from '../../domain/interfaces/IMutateExportInventory';
import { IQueryCmdbInput, IQueryCmdbOutput } from '../../domain/interfaces/IQueryCmdb';

export class ApolloClientMutateInventoryLine extends ApolloClientProvider<ResponseMutateLineInventorySave, DataMutate<Equipment>> {
	exec({ token, lang, adapter }: IAPIProps, data: DataMutate<Equipment>): Promise<ResponseMutateLineInventorySave | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateAddEquipmentExplore extends ApolloClientProvider<IMutateAddEquipmentExploreOutput, IMutateAddEquipmentExploreInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateAddEquipmentExploreInput): Promise<IMutateAddEquipmentExploreOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateAddEquipmentFromCmdb extends ApolloClientProvider<IMutateAddEquipmentFromCmdbOutput, IMutateAddEquipmentFromCmdbInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateAddEquipmentFromCmdbInput): Promise<IMutateAddEquipmentFromCmdbOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateManageCmdb extends ApolloClientProvider<IMutateManageCmdbOutput, IMutateManageCmdbInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateManageCmdbInput): Promise<IMutateManageCmdbOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEquipmentEdit extends ApolloClientProvider<IMutateEquipmentEditResponse, IMutateEquipmentEditInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEquipmentEditInput): Promise<IMutateEquipmentEditResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateAddEquipmentResilioDb extends ApolloClientProvider<IMutateAddEquipmentResilioDbOutput, IMutateAddEquipmentResilioDbInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateAddEquipmentResilioDbInput): Promise<IMutateAddEquipmentResilioDbOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryCmdb extends ApolloClientProvider<IQueryCmdbOutput, IQueryCmdbInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryCmdbInput): Promise<IQueryCmdbOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryCmdbEquipments extends ApolloClientProvider<IQueryCmdbEquipmentsOutput, IQueryCmdbEquipmentsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryCmdbEquipmentsInput): Promise<IQueryCmdbEquipmentsOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateExportInventory extends ApolloClientProvider<IMutateExportInventoryOutput, IMutateExportInventoryInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateExportInventoryInput): Promise<IMutateExportInventoryOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryCmdbTemplate extends ApolloClientProvider<IQueryCmdbTemplateOutput, IQueryCmdbTemplateInput> {}
export class ApolloClientQueryCheckResilioDbStatus extends ApolloClientProvider<IQueryCheckResilioDbStatusOutput> {}
export class ApolloClientQueryDataInventory extends ApolloClientProvider<ResponseDataInventory, { datasetId: string }> {}