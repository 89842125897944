import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './choiceSelector.css';
import { translate } from '../../../infrastructure/translations/translate';
import { useTooltip } from '../../tip/useTooltip';

export type ChoiceSelectorProps = {
	selectorName: string,
	name?: string,
	selected: string,
	list: {
		value: string,
		name: string,
		component?: JSX.Element,
		disabled?: boolean | string | React.ReactElement }[],
	handle: (value: string) => void,
	disabled?: boolean,
	placeholder?: string,
}

const ChoiceSelectorLine = (elem: ChoiceSelectorProps['list'][number] & {
	selected: string,
	selectElem: (value: string) => void,
	moveHandle: (event: React.KeyboardEvent, value: string | null) => void,
}) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const disabledBool = typeof elem.disabled === 'boolean' ? elem.disabled : elem.disabled !== undefined;
	const disabledElement = disabledBool && typeof elem.disabled !== 'boolean' && elem.disabled || translate('choiceSelector.disabled');
	useTooltip(disabledBool && showTooltip, disabledElement);
	return <>
		<li
			className={`choice_selector_list_elem ${elem.value === elem.selected ? 'selected' : ''} ${disabledBool ? 'disabled' : ''}`}
			role={'option'}
			tabIndex={elem.disabled ? -1 : 0}
			onKeyDown={(e) => elem.moveHandle(e, elem.value)}
			onClick={() => !elem.disabled && elem.selectElem(elem.value)}
			aria-label={elem.name}
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
			onFocus={() => setShowTooltip(true)}
			onBlur={() => setShowTooltip(false)}
		>
			{elem.component ?? elem.name}
		</li>
	</>;
};

/**
 * This component shows a clean selector from the list given.
 * The handle method received the value attribute.
 *
 * @param {string} selectorName: Input name for HTML
 * @param {string | null} name: Placeholder (name for humans)
 * @param {string} selected: Value of item selected
 * @param {boolean} disabled: If the selector is disabled
 * @param {{value: string, name: string, component?: JSX.Element, disabled?: boolean}[]} list: List of values (i.e. key)
 * 	and associated names (i.e. text to show) and optional component.
 * 	If component is passed it will be snhow instead of name.
 * @param {(value: string) => void} handle: Handling function from
 *  parent component, receiving the value selected.
 * @constructor
 * @return JSX.Element
 *
 * @author Maximilien
 */
const ChoiceSelector = forwardRef(({ selectorName, name, list, handle, selected, disabled, placeholder }: ChoiceSelectorProps) => {
	const [filter, setFilter] = useState('');
	const [open, setOpen] = useState(false);
	const refInput = useRef<HTMLInputElement>(null);
	const refButton = useRef<HTMLButtonElement>(null);
	const listElem = useRef<HTMLUListElement>(null);
	const input = useRef<HTMLInputElement>(null);
	const clickOutHandle = (e: MouseEvent) => {
		if (!(e.target === refInput.current || refInput.current?.contains(e.target as Node))) setOpen(false);
	};
	const KeyUpOutHandle = () => {
		if (!(document.activeElement === refInput.current || refInput.current?.contains(document.activeElement as Node))) setOpen(false);
	};
	const opening = () => {
		setOpen(true);
		setTimeout(() => {
			input.current?.focus();
		}, 1);
	}; 
	const selectElem = (value: string) => {
		setOpen(false);
		handle(value);
	};
	const moveHandle = (event: React.KeyboardEvent, value: string | null) => {
		let action = 0;
		if (event.key === 'ArrowDown') {
			action = 1;
		}
		if (event.key === 'ArrowUp') {
			action = -1;
		}
		if ([' ', 'SpaceBar', 'Enter'].includes(event.key)) {
			if (!value) return;
			selectElem(value);
			event.preventDefault();
		}
		if (event.key === 'Escape') {
			setOpen(false);
		}
		if (action !== 0) {
			const elem = event.target as HTMLLIElement;
			const parent = elem.parentElement;
			if (!parent) return;
			const list = [...parent.querySelectorAll('li')];
			let index = list.indexOf(elem);
			if (parent === refInput.current) {
				index = -1;
			}
			index += action;
			const count = (listElem.current?.childElementCount ?? 0);
			if (index <= -2) index = count - 1;
			if (index === -1 || index >= count) {
				input.current?.focus();
			} else {
				list[index].focus();
			}
		}
	};

	const dropdownPosition = () => {
		if (!refButton.current || !listElem.current) return;
		const button = refButton.current;
		const list = listElem.current;
		const rect = button.getBoundingClientRect();
		const top = rect.top + rect.height;
		const left = rect.left;
		list.style.top = `${top}px`;
		list.style.left = `${left}px`;
		list.style.maxHeight = `calc(100vh - ${top}px - 10px)`;
		list.style.minWidth = rect.width + 'px';
		list.style.maxWidth = `calc(100vw - ${left}px - 10px)`;
	};
	dropdownPosition();

	useEffect(() => {
		document.addEventListener('click', clickOutHandle);
		document.addEventListener('keyup', KeyUpOutHandle);
		window.addEventListener('resize', dropdownPosition);
		window.addEventListener('scroll', dropdownPosition);
		document.addEventListener('scroll', dropdownPosition, true);
		window.addEventListener('orientationchange', dropdownPosition);
		return () => {
			document.removeEventListener('click', clickOutHandle);
			document.removeEventListener('keyup', KeyUpOutHandle);
			window.removeEventListener('resize', dropdownPosition);
			window.removeEventListener('scroll', dropdownPosition);
			document.removeEventListener('scroll', dropdownPosition, true);
			window.removeEventListener('orientationchange', dropdownPosition);
		};
	}, []);

	const geSelectedElement = () => {
		const el = list.find(l => l.value === selected);
		if (!el) return null;
		return el.component ?? el.name;
	};

	return (<div className={`choice_selector ${open ? 'open' : ''}`} ref={refInput}>
		{name && <label htmlFor={selectorName}>{name}</label>}
		<button
			type={'button'}
			name={selectorName}
			disabled={disabled}
			id={selectorName}
			className={`choice_selector_button ${disabled ? 'choice_selector_button_disabled' : 'choice_selector_button_enabled'}`}
			onClick={opening}
			aria-label={`${name} - ${translate('choiceSelector.selector') as string}`}
			ref={refButton}
			aria-expanded={open}
		>
			<span className="choice_selector_button_content">
				{geSelectedElement() ?? placeholder}
			</span>
			<i className={`fa-solid fa-chevron-${open ? 'up' : 'down'}`}/>
		</button>
		<input disabled={disabled} onKeyDown={(e) => moveHandle(e, null)} ref={input} type="text"
			className={'choice_selector_input_filter'} value={filter} onChange={(e) => setFilter(e.target.value)}
			aria-label={translate('choiceSelector.searchInList') as string} aria-expanded={open}
		/>
		<ul className={'choice_selector_list'} ref={listElem} role={'listbox'}>
			{list
				.filter(l => !filter.length || l.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
				.map(elem => <ChoiceSelectorLine
					key={elem.value}
					{...elem}
					selected={selected}
					selectElem={selectElem}
					moveHandle={moveHandle}
				/>)}
		</ul>
	</div>);
});
ChoiceSelector.displayName = 'ChoiceSelector';
export default ChoiceSelector;
