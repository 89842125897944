import React from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import merge from 'lodash.merge';
import { getColors } from '../../../config';
import { cutLabels } from '../utils/cutLabels';

export interface StackedBarChartData {
	label: string;
	values: {
		scope: string;
		value: number;
	}[];
}

/**
 * Stacked Bar Chart
 * @param data
 * @param datasetRef
 * @param options
 * @param plugins
 * @param thin
 * @param percentage
 * @param categoryPercentage
 * @param borderRadius
 * @param borderWidth
 * @param labelLength
 * @constructor
 *
 * @author Maximilien Valenzano
 */
interface StackedBarChartProps {
	data: (StackedBarChartData | null)[];
	datasetRef?: string | null;
	options?: ChartProps<'bar'>['options'];
	plugins?: ChartProps<'bar'>['plugins'];
	thin?: number;
	percentage?: number;
	categoryPercentage?: number;
	borderRadius?: number;
	borderWidth?: number;
	labelLength?: number;
}
export const StackedBarChart = ({ data, datasetRef, options, plugins, thin, percentage, categoryPercentage, borderRadius, borderWidth, labelLength }: StackedBarChartProps): JSX.Element => {
	const [chartKey, setChartKey] = React.useState<number>(0);
	const [timeOutKey, setTimeOutKey] = React.useState<ReturnType<typeof setTimeout>>();
	const labels = data.map(d => d && cutLabels(d.label, labelLength));
	const scopes: string[] = Array.from(data.reduce<Set<string>>((p, c) => {
		if (!c) return p;
		c.values.forEach(v => p.add(v.scope));
		return p;
	}, new Set()));
	const colors = getColors(scopes.length);
	const datasets = scopes.map((scope, i) => ({
		label: scope,
		data: data.map(d => d?.values.find(v => v.scope === scope)?.value ?? null),
		backgroundColor: colors[i],
		borderColor: 'transparent'
	}));
	const barData: ChartProps<'bar'>['data'] = {
		labels,
		datasets
	};

	const maxBarHeight = data.reduce((p, c) => {
		return Math.max(p, c ? c.values.reduce((p, c) => p + c.value, 0) : 0);
	}, 0);

	if (thin !== undefined) barData.datasets.forEach(d => {
		d.maxBarThickness = thin;
	});
	if (percentage !== undefined) barData.datasets.forEach(d => {
		d.barPercentage = percentage;
	});
	if (categoryPercentage !== undefined) barData.datasets.forEach(d => {
		d.categoryPercentage = categoryPercentage;
	});
	if (borderRadius !== undefined) barData.datasets.forEach(d => {
		d.borderRadius = borderRadius;
	});
	if (borderWidth !== undefined) barData.datasets.forEach(d => {
		d.borderWidth = borderWidth;
	});

	/**
	 * About options, you can find many infos here: https://www.chartjs.org/docs/latest/axes/cartesian/
	 * `autoSkip`: If true, automatically calculates how many labels can be shown and hides labels accordingly. Labels will be rotated up to maxRotation before skipping any. Turn autoSkip off to show all labels no matter what.
	 */
	options = merge({
		scales: {
			y: {
				beginAtZero: true,
				stacked: true,
				max: maxBarHeight + maxBarHeight * 0.1,
			},
			x: {
				beginAtZero: true,
				stacked: true,
				ticks: {
					autoSkip: false,
					minRotation: 0,
					maxRotation: 45,
				},
			},
		},
		datasetRef: datasetRef
	}, options ?? {});

	const resize = () => {
		clearTimeout(timeOutKey);
		const t = setTimeout(() => {
			setChartKey(n => n + 1);
		}, 100);
		setTimeOutKey(t);
	};

	React.useEffect(() => {
		window.addEventListener('resize', resize);
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, [timeOutKey]);

	return (
		<Bar
			className='dynamic_chart'
			data={barData}
			options={options}
			plugins={plugins}
			key={chartKey}
		/>
	);
};