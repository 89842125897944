import { Study } from '../data/entries/Study';
import { Entity } from '../core/Entity';
import { IMutateStudyManagementInput } from '../interfaces/IMutateStudyManagement';
import { IMutateEditFunctionalUnitInput } from '../interfaces/IMutateEditFunctionalUnit';
import { IQueryColorsInput, IQueryColorsOutput } from '../interfaces/IQueryColors';
import { IMutateColorManagementInput, IMutateColorManagementOutput } from '../interfaces/IMutateColorManagement';
import { IFunctionalUnit } from '../interfaces/IQueryFunctionalUnits';
import { MUTATE_STUDY_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_STUDY_MANAGEMENT';
import { QUERY_STUDY } from '../../infrastructure/ApolloClient/requests/QUERY_STUDY';
import { QUERY_DATA_STUDIES } from '../../infrastructure/ApolloClient/requests/QUERY_DATA_STUDIES';
import { QUERY_FUNCTIONAL_UNITS } from '../../infrastructure/ApolloClient/requests/QUERY_FUNCTIONAL_UNITS';
import { QUERY_MY_STUDIES } from '../../infrastructure/ApolloClient/requests/QUERY_MY_STUDIES';
import { MUTATE_EDIT_FUNCTIONAL_UNIT } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_FUNCTIONAL_UNIT';
import { MUTATE_COLOR_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_COLOR_MANAGEMENT';
import { QUERY_COLORS } from '../../infrastructure/ApolloClient/requests/QUERY_COLORS';
import { QUERY_STUDY_LABELS } from '../../infrastructure/ApolloClient/requests/QUERY_STUDY_LABELS';
import {
	ApolloClientMutateColorManagement,
	ApolloClientMutateEditFunctionalUnit,
	ApolloClientMutateStudyManagement,
	ApolloClientQueryColors,
	ApolloClientQueryDataStudies,
	ApolloClientQueryFunctionalUnits,
	ApolloClientQueryMyStudies,
	ApolloClientQueryStudy,
	ApolloClientQueryStudyLabels
} from '../../infrastructure/ApolloClass/StudiesClass';

import { ApiError } from '../core/ApiError';

export class StudiesEntity extends Entity {
	public data: undefined | Study[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateStudyManagement ??= this.app.installer(ApolloClientMutateStudyManagement, MUTATE_STUDY_MANAGEMENT);
		this.app.adapter.mutateEditFunctionalUnit ??= this.app.installer(ApolloClientMutateEditFunctionalUnit, MUTATE_EDIT_FUNCTIONAL_UNIT);
		this.app.adapter.mutateColorManagement ??= this.app.installer(ApolloClientMutateColorManagement, MUTATE_COLOR_MANAGEMENT);
		this.app.adapter.queryMyStudies ??= this.app.installer(ApolloClientQueryMyStudies, QUERY_MY_STUDIES);
		this.app.adapter.queryStudy ??= this.app.installer(ApolloClientQueryStudy, QUERY_STUDY);
		this.app.adapter.queryDataStudies ??= this.app.installer(ApolloClientQueryDataStudies, QUERY_DATA_STUDIES);
		this.app.adapter.queryFunctionalUnits ??= this.app.installer(ApolloClientQueryFunctionalUnits, QUERY_FUNCTIONAL_UNITS);
		this.app.adapter.queryColors ??= this.app.installer(ApolloClientQueryColors, QUERY_COLORS);
		this.app.adapter.queryStudyLabels ??= this.app.installer(ApolloClientQueryStudyLabels, QUERY_STUDY_LABELS);
		this.app.adapter.storeDataStudies?.({ loading: false, data: this, error: null });
	}

	get(): Study[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryMyStudies();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		this.data.sort((a, b) => {
			if (a.year > b.year) return -1;
			if (a.year < b.year) return 1;
			const company: number = a.companyFk.name.localeCompare(b.companyFk.name);
			if (company !== 0) return company;
			return a.name.localeCompare(b.name);
		});
		return this.app.adapter.storeDataStudies?.({ loading: this._isQuerying, data: this, error: null });
	}

	set(obj: Study[] | undefined): void {
		if (!obj) this.data = undefined;
		else if (!this.data) {
			this.data = obj;
		} else {
			obj.forEach(s => {
				if (this.data) {
					const idx = this.data.findIndex(d => d.id === s.id);
					if (idx === -1) {
						this.data.push(s);
					} else {
						Object.assign(this.data[idx], s);
					}
				}
			});
		}
		this.change();
	}

	update(study: Study): void {
		if (!study) return;
		if (!this.data) this.data = [];
		const idx = this.data?.findIndex(s => s.id === study.id);
		if (idx === undefined || idx === -1) {
			this.data.push(study);
		} else if (this.data) {
			this.data[idx] = study;
		}
		this.change();
	}

	delete(id: string) {
		// This line update the data in the entity, filter will return a new array without the deleted study
		this.data = this.data?.filter(s => s.id !== id);
		this.change();
	}

	addDatasetId(studyId: string, datasetId: string): void {
		const study = this.data?.find(s => s.id === studyId);
		if (!study) return;
		if (study.datasetsId.includes(datasetId)) return;
		study.datasetsId.push(datasetId);
		this.change();
	}

	deleteDatasetId(studyId: string, datasetId: string) {
		const study = this.data?.find(s => s.id === studyId);
		if (!study) return;
		const index = study.datasetsId.findIndex(d => d === datasetId);
		if (index === -1) return;
		study.datasetsId.splice(index, 1);
		this.change();
	}

	setLoading(loading: boolean) {
		this.app.adapter.storeDataStudies?.({ loading, data: this, error: null });
	}

	private _storeError(error: Error | ApiError) {
		this.app.adapter.storeDataStudies?.({ loading: false, data: this, error });
	}
	/***************************************************
	 * 					STUDIES						   *
	 ***************************************************/

	async queryMyStudies(): Promise<void> {
		this.setLoading(true);
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryMyStudies).catch((err) => {
			this._isQuerying = false;
			if (!err) {
				this.set([]);
				return undefined;
			}
			this._storeError(err);
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.myStudies);
	}

	async queryStudy(studyId: string): Promise<Study> {
		const study = this.data?.find(s => s.id === studyId);
		if (study) return study;

		const data = await this.callApi(this.app.adapter.queryStudy, { studyId });
		this.update(data.study);
		return data.study;
	}

	// TODO Tanguy: This call can be replaced by queryMyStudies
	async queryDataStudies(): Promise<void> {
		const data = await this.callApi(this.app.adapter.queryDataStudies);
		this.set(data.myStudies);
	}

	async queryColors(input: IQueryColorsInput): Promise<IQueryColorsOutput | undefined> {
		const data = await this.callApi(this.app.adapter.queryColors, input);
		this.app.entities.indicators.addColors(data.colors.indicators);
		this.app.entities.lifeCycleSteps.addColors(data.colors.lifeCycleSteps);
		this.app.entities.blocks.addColors(data.colors.blocks);
		return data;
	}

	async queryStudyLabels(): Promise<string[]> {
		const data = await this.callApi(this.app.adapter.queryStudyLabels);
		return data.studyLabels;
	}

	async mutateStudyManagement(study: IMutateStudyManagementInput): Promise<void> {
		const data = await this.callApi(this.app.adapter.mutateStudyManagement, study);
		if (study.remove) {
			this.delete(study.id);
			this.app.entities.datasets.deleteFromStudy(study.id);
		}
		else {
			this.update(data?.mutateStudyManagement.study);
		}
	}

	async mutateColorManagement(input: IMutateColorManagementInput): Promise<IMutateColorManagementOutput> {
		const data = await this.callApi(this.app.adapter.mutateColorManagement, input);
		this.app.entities.indicators.addColors(data.mutateColorManagement.colors.indicators);
		this.app.entities.lifeCycleSteps.addColors(data.mutateColorManagement.colors.lifeCycleSteps);
		this.app.entities.blocks.addColors(data.mutateColorManagement.colors.blocks);
		return data;
	}

	/***************************************************
	 * 				FUNCTIONAL UNITS				   *
	 ***************************************************/

	async queryFunctionalUnits(): Promise<IFunctionalUnit[]> {
		const data = await this.callApi(this.app.adapter.queryFunctionalUnits);
		return data.functionalUnits;
	}

	async mutateEditFunctionalUnit(functionalUnit: IMutateEditFunctionalUnitInput): Promise<IFunctionalUnit> {
		const data = await this.callApi(this.app.adapter.mutateEditFunctionalUnit, functionalUnit);
		return data.mutateEditFunctionalUnit.functionalUnit;
	}
}
