import React, { useEffect, useState } from 'react';
import './GlobalLoading.scss';
import logo from '../../../media/logo.svg';
import { useUser } from '../../hooks/useUser';

/**
 * LeafSvg component
 * @description Renders an SVG leaf icon with customizable color.
 *
 * @param {'green' | 'gray'} [color="gray"] - The color of the leaf icon.
 * @returns {JSX.Element} The rendered LeafSvg component.
 */
const LeafSvg = ({ color = 'gray' }: { color?: 'green' | 'gray' }) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			xmlns="http://www.w3.org/2000/svg"
			className={color}
			preserveAspectRatio={'xMidYMid slice'}
		>
			<svg width="40" height="40" viewBox="0 0 15 8" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 3.59071C14.9985 3.60962 14.9933 3.62804 14.9845 3.64488C14.9758 3.66172 14.9638 3.67663 14.9492 3.68873C11.2949 6.59325 8.17621 7.68244 5.67651 6.95631C4.90961 6.73529 4.19928 6.35175 3.59379 5.83178C2.98831 5.31181 2.50185 4.66758 2.16749 3.94288H0.363064C0.266774 3.94288 0.174427 3.90463 0.106339 3.83654C0.038251 3.76845 0 3.6761 0 3.57981C0 3.48352 0.038251 3.39118 0.106339 3.32309C0.174427 3.255 0.266774 3.21675 0.363064 3.21675H2.16749C2.50322 2.49455 2.99032 1.85298 3.59576 1.33556C4.2012 0.81813 4.91082 0.436941 5.67651 0.217839C8.17621 -0.50829 11.304 0.580903 14.9492 3.48542C14.9784 3.51013 14.9966 3.54535 15 3.58344" />
			</svg>
		</svg>
	);
};

/**
 * Global loading component
 * @description Displays a loading state with animated leaf icons and an optional logo.
 *
 * @param {string} [text] - The text to display above the loading bar.
 * @returns {JSX.Element} The rendered Bar component with animated leaf icons.
 */
export const GlobalLoading = ({ text }: { text?: string }): JSX.Element => {
	const { data: user } = useUser();
	const logoImg = user?.myCompany?.style ? user?.myCompany?.logo : null;
	const [activeLeaf, setActiveLeaf] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveLeaf((prev) => (prev + 1) % 3);
		}, 500);

		return () => clearInterval(interval as NodeJS.Timeout);
	}, []);

	return (
		<div className={'loading-wrap'}>
			{logoImg
				? <img alt={'Company logo'} src={logoImg} width={'50px'}/>
				: <img alt="Resilio logo" src={logo} width={'50px'} />}
			{text ? <h3>{text}</h3> : null}
			<div className={'leaf-loader'}>
				<LeafSvg color={activeLeaf === 0 ? 'green' : 'gray'} />
				<LeafSvg color={activeLeaf === 1 ? 'green' : 'gray'} />
				<LeafSvg color={activeLeaf === 2 ? 'green' : 'gray'} />
			</div>
		</div>
	);
};