import React from 'react';
import './LabelList.scss';

export interface LabelType {
	name: string;
}

interface LabelProps {
	label: LabelType;
	isSelectable?: boolean;
	isSelected?: boolean;
	onClick?: (id: string) => void;
	className?: string;
}

const Label = ({
	label,
	isSelectable = false,
	isSelected = false,
	onClick,
	className
}: LabelProps) => {
	const handleClick = () => {
		if (isSelectable && onClick) {
			onClick(label.name);
		}
	};

	return (
		<div
			className={`
        label
        ${isSelectable ? 'label_selectable' : ''}
        ${isSelected || !isSelectable ? 'label_selected' : ''}
        ${className || ''}
      `}
			onClick={handleClick}
		>
			{label.name}
		</div>
	);
};

interface LabelListProps {
	labels: LabelType[];
	isSelectable?: boolean;
	selectedLabels?: string[];
	onSelectionChange?: (selectedIds: string[]) => void;
	className?: string;
}
export const LabelList = ({
	labels,
	isSelectable = false,
	selectedLabels = [],
	onSelectionChange,
	className
}: LabelListProps) => {
	const handleLabelClick = (labelName: string) => {
		if (!isSelectable || !onSelectionChange) return;

		const newSelection = selectedLabels.includes(labelName)
			? selectedLabels.filter(name => name !== labelName)
			: [...selectedLabels, labelName];

		onSelectionChange(newSelection);
	};

	return (
		<div className={`label_list ${className || ''}`}>
			{labels.map(label => (
				<Label
					key={label.name}
					label={label}
					isSelectable={isSelectable}
					isSelected={selectedLabels.includes(label.name)}
					onClick={handleLabelClick}
				/>
			))}
		</div>
	);
};