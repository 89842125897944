import { adapter } from '../infrastructure';

export const successNotification = (id: string, message: string): void => {
	adapter.storeNotification({
		id,
		message,
		type: 'success'
	});
};

export const errorNotification = (id: string, message: string): void => {
	adapter.storeNotification({
		id,
		message,
		type: 'error'
	});
};

export const warningNotification = (id: string, message: string): void => {
	adapter.storeNotification({
		id,
		message,
		type: 'warning'
	});
};

export const infoNotification = (id: string, message: string): void => {
	adapter.storeNotification({
		id,
		message,
		type: 'info'
	});
};