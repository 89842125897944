import { ChartSkeleton } from '../../viewComponents/skeletons/results/ChartSkeleton';
import { TableSkeleton } from '../../viewComponents/skeletons/results/TableSkeleton';
import React from 'react';

export type SkeletonType = 'chart' | 'table';

export interface ResultSkeletonProps {
	type?: SkeletonType;
}

export const ResultSkeleton: React.FC<ResultSkeletonProps> = ({ type = 'table' }: ResultSkeletonProps) => {
	return type === 'chart' ? (
		<ChartSkeleton />
	) : (
		<TableSkeleton />
	);
};