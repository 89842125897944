import { SelfUser } from '../domain/interfaces/ResponseUserData';

/**
 * return if user has permissions
 * @param {SelfUser} user - user object to check permissions
 * @param {string} permission
 * @returns {boolean}
 *
 * @author Maximilien Valenzano
 */
export const checkUserPermission = (user: SelfUser | undefined, permission: string | string[]): boolean => {
	// if permission is an array of permission, we check if the user has all of permissions
	if (!user) return false;
	if (permission instanceof Array) {
		let hasPerm = true;
		for (const perm of permission) {
			if (!user?.permissions.includes(perm)) {
				hasPerm = false;
				break;
			}
		}
		return hasPerm;
	}
	// if permission is a string, we check if the user has the permission
	return user?.permissions.includes(permission) ?? false;
};